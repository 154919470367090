import { PROJECTS_COLLECTION } from './Project';

export const TASK_DATE_FORMAT = 'YYYY-MM-DD';

export interface ProjectScheduleTask {
	id: string;
	name: string;
	start: string;
	end: string;
	/**
	 * Progress in percentage
	 */
	progress: number;
	dependencies: string[];
	description?: string;
}

export const SCHEDULE_COLLECTION = 'schedule';

export const getProjectScheduleCollectionString = (projectId: string) =>
	`${PROJECTS_COLLECTION}/${projectId}/${SCHEDULE_COLLECTION}`;
