import {
	logInOutline,
	logInSharp,
	personOutline,
	personSharp,
} from 'ionicons/icons';
import {
	ASSIGNMENTS_ICON,
	BOARD_ICON,
	CHECKLIST_TEMPLATE_ICON,
	DAILY_REPORT_SUMMARY_ICON,
	FILLABLE_FORMS_ICON,
	NOTIFICATIONS_ICON,
	ORGANIZATION_ICON,
	PORTFOLIO_ICON,
	PROJECTS_ICON,
} from '../../pages/icons';
import {
	BOARDS_ROUTE,
	CHECKLIST_TEMPLATES_ROUTE,
	DAILY_REPORT_TEMPLATE_ROUTE,
	FORM_UPLOADS_ROUTE,
	LOGIN_ROUTE,
	MY_ASSIGNMENTS_ROUTE,
	MY_NOTIFICATIONS_ROUTE,
	MY_PROJECTS_ROUTE,
	ORGANIZATION_SETTINGS_ROUTE,
	PORTFOLIOS_ROUTE,
	PROFILE_ROUTE,
	PROJECTS_ROUTE,
} from '../../Routes';
import { AppPage } from './types';

export const commonAppPages: AppPage[] = [];

export const loggedOutAppPages: AppPage[] = [
	{
		titleToTranslate: 'login',
		url: LOGIN_ROUTE,
		iosIcon: logInOutline,
		mdIcon: logInSharp,
	},
];

export const userAppPages: AppPage[] = [
	{
		titleToTranslate: 'notifications',
		url: MY_NOTIFICATIONS_ROUTE,
		iosIcon: NOTIFICATIONS_ICON,
		mdIcon: NOTIFICATIONS_ICON,
	},
	{
		titleToTranslate: 'myAssignments',
		url: MY_ASSIGNMENTS_ROUTE,
		iosIcon: ASSIGNMENTS_ICON,
		mdIcon: ASSIGNMENTS_ICON,
	},
	{
		titleToTranslate: 'myProjects',
		url: MY_PROJECTS_ROUTE,
		iosIcon: PROJECTS_ICON,
		mdIcon: PROJECTS_ICON,
	},
	{
		titleToTranslate: 'profile',
		url: PROFILE_ROUTE,
		iosIcon: personOutline,
		mdIcon: personSharp,
	},
];

export const loggedInAppPages: AppPage[] = [];

export const adminAppPages: AppPage[] = [
	{
		titleToTranslate: 'portfolio',
		url: PORTFOLIOS_ROUTE,
		iosIcon: PORTFOLIO_ICON,
		mdIcon: PORTFOLIO_ICON,
	},
	{
		titleToTranslate: 'projects',
		url: PROJECTS_ROUTE,
		iosIcon: PROJECTS_ICON,
		mdIcon: PROJECTS_ICON,
	},
	{
		titleToTranslate: 'boards',
		url: BOARDS_ROUTE,
		iosIcon: BOARD_ICON,
		mdIcon: BOARD_ICON,
	},
	{
		titleToTranslate: 'organization',
		url: ORGANIZATION_SETTINGS_ROUTE,
		iosIcon: ORGANIZATION_ICON,
		mdIcon: ORGANIZATION_ICON,
	},
	{
		titleToTranslate: 'checklistTemplates',
		url: CHECKLIST_TEMPLATES_ROUTE,
		iosIcon: CHECKLIST_TEMPLATE_ICON,
		mdIcon: CHECKLIST_TEMPLATE_ICON,
	},
	{
		titleToTranslate: 'dailyReportSummaryTemplates',
		url: DAILY_REPORT_TEMPLATE_ROUTE,
		iosIcon: DAILY_REPORT_SUMMARY_ICON,
		mdIcon: DAILY_REPORT_SUMMARY_ICON,
	},
	{
		titleToTranslate: 'formUploads',
		url: FORM_UPLOADS_ROUTE,
		iosIcon: FILLABLE_FORMS_ICON,
		mdIcon: FILLABLE_FORMS_ICON,
	},
];
