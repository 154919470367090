import {
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonHeader,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonPage,
	IonProgressBar,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonToast,
} from '@ionic/react';
import {
	FillableFormUpload,
	getOrganizationFillableFormPath,
} from '@omega/shared';
import { uploadBytes } from 'firebase/storage';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PdfPreview from '../../components/pdf-preview/PdfPreview';
import { getFileRef } from '../../database/file';
import { saveFillableForm } from '../../database/fillable-form';
import { useOrganizationState } from '../../state';
import { ionRegister } from '../../util/ionRegister';
import { EditFillableFormUploadProps } from './types';

const EditFillableFormUpload: React.FC<EditFillableFormUploadProps> = ({
	edit,
	isOpen: open,
	onClose,
}) => {
	const { t } = useTranslation();
	const fileSelectRef = useRef<HTMLInputElement>(null);
	const [saving, setIsSaving] = useState(false);
	const [presentErrorToast] = useIonToast();
	const [pdfUrl, setPdfUrl] = useState<string>();
	const [uploadedFile, setUploadedFile] = useState<File>();
	const [thumbnailPreview, setThumbnailPreview] = useState<string>();

	const orgState = useOrganizationState();
	const orgId = orgState.value?.id;
	const formFields = useForm<FillableFormUpload>({
		defaultValues: edit ?? {},
	});

	useEffect(() => {
		if (edit) {
			formFields.reset(edit);
		}
	}, [edit, formFields]);

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = formFields;

	useEffect(() => {
		if (uploadedFile) {
			const objectUrl = URL.createObjectURL(uploadedFile);
			setPdfUrl(objectUrl);
		}
	}, [uploadedFile]);

	const save = async () => {
		if (
			!orgId ||
			(!thumbnailPreview && !edit?.thumbnail) ||
			(!edit?.file && !uploadedFile)
		) {
			return;
		}
		setIsSaving(true);

		handleSubmit(
			async data => {
				const toSave: FillableFormUpload = {
					...data,
					id: data.id ?? nanoid(),
				};
				if (saving) {
					return;
				}
				if (uploadedFile) {
					const path = getOrganizationFillableFormPath(
						orgId,
						toSave.id,
						uploadedFile.name,
					);
					const fileRef = getFileRef(path);
					await uploadBytes(fileRef, uploadedFile, {
						cacheControl: 'max-age=86400',
					});
					toSave.file = path;
				}
				if (thumbnailPreview) {
					const thumbnailPath = `thumbnail-${toSave.file}`;
					const thumbRef = getFileRef(thumbnailPath);
					const blob = await (await fetch(thumbnailPreview)).blob();
					await uploadBytes(thumbRef, blob, {
						cacheControl: 'max-age=86400',
					});
					toSave.thumbnail = thumbnailPath;
				}

				await saveFillableForm(orgId, toSave);
				reset({});
				setIsSaving(false);
				onClose();
			},
			async (errors: Object) => {
				presentErrorToast(t('missingRequiredFields'), 5000);
				console.error('Missing required fields', errors);
				setIsSaving(false);
			},
		)();
	};
	const onFileChange = (fileChangeEvent: any) => {
		Array.from(fileChangeEvent.target.files).forEach((file: any) => {
			setUploadedFile(file);
		});
	};

	const renderUpload = () => {
		return (
			<>
				<input
					accept="application/pdf"
					className="hidden-input"
					ref={fileSelectRef}
					onChange={ev => onFileChange(ev)}
					type="file"
				/>
				<div className="w-full flex justify-center">
					<IonButton onClick={() => fileSelectRef.current?.click()}>
						{t('upload')}
					</IonButton>
				</div>
				<div className="w-full flex justify-center">
					{thumbnailPreview && <img src={thumbnailPreview} alt="preview" />}
				</div>
			</>
		);
	};

	return (
		<IonModal onIonModalDidDismiss={onClose} isOpen={open}>
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonTitle>
							{edit ? t('editFormUpload') : t('createFormUpload')}
						</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonItem className={errors.name ? 'input-error' : ''}>
									<IonLabel className="input-required" position="stacked">
										{t('formName')}
									</IonLabel>
									<IonInput
										spellCheck
										id="name-input"
										{...ionRegister(register('name', { required: true }))}
										placeholder={t('formName')}
									></IonInput>
								</IonItem>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonItem>
									<IonLabel position="stacked">{t('description')}</IonLabel>
									<IonInput
										spellCheck
										id="description-input"
										{...ionRegister(
											register('description', { required: false }),
										)}
										placeholder={t('description')}
									></IonInput>
								</IonItem>
							</IonCol>
						</IonRow>
						{renderUpload()}
						{pdfUrl && (
							<PdfPreview pdfUrl={pdfUrl} generatedPdf={setThumbnailPreview} />
						)}
					</IonGrid>
				</IonContent>
				<IonFooter>
					{saving && <IonProgressBar type="indeterminate" />}
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={onClose} color="danger">
								{t('cancel')}
							</IonButton>
							<IonButton
								disabled={saving}
								id="save-fillable-form-button"
								onClick={save}
							>
								{t('save')}
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonFooter>
			</IonPage>
		</IonModal>
	);
};

export default EditFillableFormUpload;
