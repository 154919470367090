export interface FieldValues {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

export interface FieldConfig {
	id: string;
	name: string;
	description?: string;
	type: FieldConfigType;
	required?: boolean;

	// Select fields
	multiple?: boolean;
	options?: LabelValue[];

	// number fields
	stepSize?: number;
	min?: number;
	max?: number;

	// Postfix the value field.
	postfix?: string;
}

export interface LabelValue {
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
}

export enum FieldConfigType {
	TEXT = 'TEXT',
	NUMBER = 'NUMBER',
	// SELECT = 'SELECT',
	TEXT_AREA = 'TEXT_AREA',
	CHECKBOX = 'CHECKBOX',
	SWITCH = 'SWITCH',
}
