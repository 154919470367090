import { PROJECTS_COLLECTION } from './Project';

export const DRAWINGS_COLLECTION = 'drawings';
export const DRAWINGS_NOTES_COLLECTION = 'notes';
export const DRAWINGS_REVISIONS_COLLECTION = 'revisions';

export const getProjectDrawingsCollection = (projectId: string) => {
	return `${PROJECTS_COLLECTION}/${projectId}/${DRAWINGS_COLLECTION}`;
};

export const getProjectDrawingsFile = (
	projectId: string,
	drawingId: string,
	drawing: string,
) => {
	return `${PROJECTS_COLLECTION}/${projectId}/${DRAWINGS_COLLECTION}/${drawingId}/${drawing}`;
};

// projects/:projectId/drawing/:drawingId/:checklistItemId
export const getProjectDrawingNotesCollection = (
	projectId: string,
	drawingId: string,
	revisionId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${DRAWINGS_COLLECTION}/${drawingId}/${DRAWINGS_REVISIONS_COLLECTION}/${revisionId}/${DRAWINGS_NOTES_COLLECTION}`;

export enum DrawingStatus {
	DRAFT = 'DRAFT',
	IN_PROGRESS = 'IN_PROGRESS',
	FINALIZED = 'FINALIZED',
	REMOVED = 'REMOVED',
}

export interface Drawing {
	id: string;
	name: string;
	status: DrawingStatus;
	type: DrawingType;
	subject: DrawingSubject;
	author: string;
	location?: string;
	revisions: DrawingFile[];
	recentTimestamp: Date;
}

export interface DrawingFile {
	id: string;
	file: string;
	thumbnail?: string;
	timestamp: Date;
	revisionNumber: number;
}

export enum DrawingType {
	DRAWING = 'DRAWING',
	SPECIFICATION = 'SPECIFICATION',
}

export enum DrawingSubject {
	SOLAR_AC = 'SOLAR_AC',
	SOLAR_DC = 'SOLAR_DC',
	ELECTRICAL = 'ELECTRICAL',
	ROOFING = 'ROOFING',
	ARCHITECTURAL = 'ARCHITECTURAL',
	CIVIL = 'CIVIL',
	CONVEYING_SYSTEM = 'CONVEYING_SYSTEM',
	INTERIOR_DESIGN = 'INTERIOR_DESIGN',
	KITCHEN_EQUIPMENT = 'KITCHEN_EQUIPMENT',
	LANDSCAPE = 'LANDSCAPE',
	MECHANICAL = 'MECHANICAL',
	OTHER = 'OTHER',
	PLUMBING = 'PLUMBING',
	STRUCTURAL = 'STRUCTURAL',
}
