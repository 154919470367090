import {
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonLabel,
	IonRow,
	useIonToast,
} from '@ionic/react';
import { Organization } from '@omega/shared';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	getCreateOrganization,
	saveOrganization,
} from '../../database/organization';
import { ionRegister } from '../../util/ionRegister';
import GooglePlacesAutocomplete from '../google-places-autocomplete/GooglePlacesAutocomplete';
import { PlaceResult } from '../google-places-autocomplete/types';
import PhoneNumberForm from '../phone-number-form/PhoneNumberForm';

export interface CreateOrganizationProps {
	edit?: Organization;
	saveComplete: (success: boolean) => void;
	shouldSave: boolean;
}

const CreateOrganization: React.FC<CreateOrganizationProps> = ({
	saveComplete,
	shouldSave,
	edit,
}) => {
	const { t } = useTranslation();
	const [saving, setSaving] = useState<boolean>(false);
	const attributions = useRef<HTMLDivElement>(null);

	const defaultValues: Partial<Organization> = useMemo(() => {
		return edit
			? edit
			: {
					phoneNumbers: [
						{
							label: 'office',
							number: '',
						},
					],
			  };
	}, [edit]);

	const formMethods = useForm<Organization>({
		defaultValues,
	});
	const {
		handleSubmit,
		formState: { errors },
		register,
	} = formMethods;

	const [presentErrorToast] = useIonToast();

	useEffect(() => {
		if (shouldSave) {
			handleSubmit(
				async (data: Organization) => {
					setSaving(true);
					if (saving) {
						return;
					}
					try {
						if (data.id) {
							await saveOrganization(data);
						} else if (!data.id) {
							data.id = nanoid();
							await getCreateOrganization()(data);
						}
					} catch (ex) {
						console.error('There was an issue saving the organization', ex);
						presentErrorToast(t('createOrganizationFailed'), 5000);
					} finally {
						saveComplete(true);
						setSaving(false);
					}
				},
				async (errors: Object) => {
					presentErrorToast(t('missingRequiredFields'), 5000);
					console.error('Missing required fields', errors);
					saveComplete(false);
					setSaving(false);
				},
			)();
		}
	}, [handleSubmit, presentErrorToast, saveComplete, saving, shouldSave, t]);

	// Handle the update from the place result
	const placesChange = (change: PlaceResult) => {
		const addressComponents = change.address_components;
		const locality =
			addressComponents.find(comp => comp.types.includes('locality'))
				?.long_name ?? '';
		formMethods.setValue('city', locality);
		const adminArea =
			addressComponents.find(comp =>
				comp.types.includes('administrative_area_level_1'),
			)?.long_name ?? '';

		formMethods.setValue('state', adminArea);
		const postalCode =
			addressComponents.find(comp => comp.types.includes('postal_code'))
				?.long_name ?? '';
		formMethods.setValue('zipCode', postalCode);
	};
	const address = formMethods.watch('address', edit?.address ?? '') ?? '';

	return (
		<FormProvider {...formMethods}>
			<div className="flex flex-col justify-center items-center">
				<h1 className="text-xl my-3">{t('createOrganization')}</h1>
				<p className="m-3">{t('createOrgMessage')}</p>
			</div>

			<IonGrid>
				<IonRow>
					<IonCol>
						<IonItem className={errors.name ? 'input-error' : ''}>
							<IonLabel className="input-required" position="stacked">
								{t('organizationName')}
							</IonLabel>
							<IonInput
								spellCheck
								{...ionRegister(register('name', { required: true }))}
								placeholder={t('organizationName')}
							></IonInput>
						</IonItem>
					</IonCol>
					<IonCol>
						<IonItem className={errors.email ? 'input-error' : ''}>
							<IonLabel className="input-required" position="stacked">
								{t('contactEmail')}
							</IonLabel>
							<IonInput
								spellCheck
								{...ionRegister(register('email', { required: true }))}
								placeholder={t('contactEmail')}
							></IonInput>
						</IonItem>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						{attributions.current && (
							<GooglePlacesAutocomplete
								className={errors.address ? `input-error` : ''}
								inputClassName="input-required"
								onInputChange={address =>
									formMethods.setValue('address', address)
								}
								inputValue={address}
								onPlaceSelect={placesChange}
								attributions={attributions.current}
							/>
						)}
					</IonCol>
				</IonRow>
			</IonGrid>
			<PhoneNumberForm require />

			<div ref={attributions} id="attributions"></div>
		</FormProvider>
	);
};

export default CreateOrganization;
