import { ORGANIZATIONS_COLLECTION } from './Organization';

export const ORGANIZATION_TEAMS_COLLECTION = 'teams';
export const ORGANIZATION_TEAM_DUTIES = 'team-duties';
export const DUTIES_ITEM = 'duties';

export const getOrganizationTeamsCollectionPath = (id: string) => {
	return `${ORGANIZATIONS_COLLECTION}/${id}/${ORGANIZATION_TEAMS_COLLECTION}`;
};

export const getOrganizationDutiesDocPath = (organizationId: string) => {
	return `${ORGANIZATIONS_COLLECTION}/${organizationId}/${ORGANIZATION_TEAM_DUTIES}/${DUTIES_ITEM}`;
};

export interface OrganizationTeam {
	id: string;
	name: string;
	description?: string;
	positions: TeamPosition[];
}

export enum ProjectRole {
	ADMINISTRATOR = 'ADMINISTRATOR',
	CONTRIBUTOR = 'CONTRIBUTOR',
	VIEWER = 'VIEWER',
	DEVELOPER = 'DEVELOPER',
}

export interface TeamPosition {
	id: string;
	name: string;
	description?: string;
	roles: ProjectRole[];
	defaultUsers: string[];
	duties?: string[];
}
