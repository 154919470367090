// Creates a thumbnail fitted insize the boundBox (w x h)
export function generateThumbnail(
	file: File,
	boundBox: number[],
): Promise<string> {
	if (!boundBox || boundBox.length !== 2) {
		throw new Error('You need to give the boundBox');
	}
	const reader = new FileReader();
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	return new Promise((resolve, reject) => {
		reader.onload = function (event) {
			const img = new Image();
			img.onload = function () {
				if (ctx == null) {
					reject('could not get canvas context');
					return;
				}
				const scaleRatio =
					Math.min(...boundBox) / Math.max(img.width, img.height);
				const w = img.width * scaleRatio;
				const h = img.height * scaleRatio;
				canvas.width = w;
				canvas.height = h;
				ctx.drawImage(img, 0, 0, w, h);
				return resolve(canvas.toDataURL(file.type));
			};
			img.src = event?.target?.result ? (event.target.result as string) : '';
		};
		reader.readAsDataURL(file);
	});
}

export function generateThumbnailFromBlob(blob: Blob, boundBox: number[]) {
	if (!boundBox || boundBox.length !== 2) {
		throw new Error('You need to give the boundBox');
	}
	const reader = new FileReader();
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	return new Promise<Blob | null>(resolve => {
		reader.onload = function (event) {
			const img = new Image();
			img.onload = function () {
				if (ctx == null) {
					resolve(null);
					return;
				}
				const scaleRatio =
					Math.min(...boundBox) / Math.max(img.width, img.height);
				const w = img.width * scaleRatio;
				const h = img.height * scaleRatio;
				canvas.width = w;
				canvas.height = h;
				ctx.drawImage(img, 0, 0, w, h);
				canvas.toBlob(blob => {
					resolve(blob);
				});
			};
			img.src = event?.target?.result ? (event.target.result as string) : '';
		};
		reader.readAsDataURL(blob);
	});
}

export function generateThumbnailUrlFromBlob(blob: Blob, boundBox: number[]) {
	if (!boundBox || boundBox.length !== 2) {
		throw new Error('You need to give the boundBox');
	}
	const reader = new FileReader();
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	return new Promise<string | null>(resolve => {
		reader.onload = function (event) {
			const img = new Image();
			img.onerror = e => {
				console.log('failed to load image');
				console.log(e);
			};
			img.onload = function () {
				if (ctx == null) {
					resolve(null);
					return;
				}
				const scaleRatio =
					Math.min(...boundBox) / Math.max(img.width, img.height);
				const w = img.width * scaleRatio;
				const h = img.height * scaleRatio;
				canvas.width = w;
				canvas.height = h;
				ctx.drawImage(img, 0, 0, w, h);
				resolve(canvas.toDataURL());
			};
			img.src = event?.target?.result ? (event.target.result as string) : '';
		};
		reader.readAsDataURL(blob);
	});
}

export function isFileImage(file: File) {
	return file && file.type.split('/')[0] === 'image';
}

export function isBlobImage(blob: Blob) {
	return blob && blob.type.split('/')[0] === 'image';
}

export async function makePdfThumb(
	canvas: HTMLCanvasElement,
	boundBox: number[],
): Promise<string | null> {
	const blob = await new Promise<Blob | null>(resolve => {
		try {
			canvas.toBlob(resolve);
		} catch (ex) {
			console.log('There was a problem generating the thumb.');
			console.log(ex);
		}
	});
	if (blob) {
		var reader = new FileReader();
		reader.readAsDataURL(blob);
		return new Promise<string>(res => {
			reader.onloadend = function () {
				const base64data = reader.result as string;
				res(base64data);
			};
		});

		//return generateThumbnailUrlFromBlob(blob, boundBox);
	}
	return null;
}
