import {
	IonButton,
	IonButtons,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonNote,
	useIonAlert,
	useIonPopover,
} from '@ionic/react';
import { OrganizationRole, ProjectBookmark, ProjectRole } from '@omega/shared';
import { trashOutline } from 'ionicons/icons';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useUserHasPrivilege } from '../../database/permissions';
import {
	deleteProjectBookmark,
	saveProjectAllBookmark,
	saveProjectBookmark,
	useProjectAllBookmarks,
	useProjectBookmarks,
} from '../../database/project';
import { useOmegaUser } from '../../database/user';
import './style.css';
import { ProjectBookmarkButtonProps } from './types';

const BookmarkPopover: React.FC<{
	projectId: string;
	addClick: () => void;
	addProjectClick: () => void;
	route: (route: string) => void;
}> = ({ addClick, projectId, route, addProjectClick }) => {
	const { t } = useTranslation();
	const [present] = useIonAlert();
	const [bookmarks] = useProjectBookmarks(projectId);
	const [allBookmarks] = useProjectAllBookmarks(projectId);

	const isAdmin = useUserHasPrivilege(
		[OrganizationRole.OWNER, OrganizationRole.ADMIN],
		[ProjectRole.ADMINISTRATOR],
		projectId,
	);

	const isContributor = useUserHasPrivilege(
		[
			OrganizationRole.OWNER,
			OrganizationRole.ADMIN,
			OrganizationRole.CONTRIBUTOR,
		],
		[ProjectRole.ADMINISTRATOR, ProjectRole.CONTRIBUTOR],
		projectId,
	);

	const askDelete = (id: string) => {
		present({
			header: t('deleteBookmark'),
			buttons: [
				'Cancel',
				{
					text: t('delete'),
					handler: () => {
						deleteProjectBookmark(projectId, id);
					},
				},
			],
		});
	};

	const renderBookmark = (bookmark: ProjectBookmark, canDelete: boolean) => {
		return (
			<IonItem
				key={bookmark.id}
				button
				detail
				onClick={() => {
					route(bookmark.path);
				}}
			>
				<IonLabel>{bookmark.name}</IonLabel>
				{canDelete && (
					<IonButtons slot="end">
						<IonButton
							color="danger"
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								askDelete(bookmark.id);
							}}
						>
							<IonIcon size="small" slot="icon-only" icon={trashOutline} />
						</IonButton>
					</IonButtons>
				)}
			</IonItem>
		);
	};

	return (
		<IonContent>
			<IonList lines="full">
				<IonListHeader>{t('myBookmarks')}</IonListHeader>
				{bookmarks?.docs.map(bookmarkDoc => {
					const bookmark = bookmarkDoc.data();
					return renderBookmark(bookmark, true);
				})}
				{(!bookmarks || !bookmarks.docs.length) && (
					<IonItem>
						<IonNote>{t('noBookmarks')}</IonNote>
					</IonItem>
				)}

				<IonListHeader>{t('projectBookmarks')}</IonListHeader>
				{(!allBookmarks || !allBookmarks.docs.length) && (
					<IonItem>
						<IonNote>{t('noProjectBookmarks')}</IonNote>
					</IonItem>
				)}
				{allBookmarks?.docs.map(bookmarkDoc => {
					const bookmark = bookmarkDoc.data();
					return renderBookmark(bookmark, isAdmin);
				})}
				{isContributor && (
					<IonItem lines="none" button detail onClick={addClick}>
						{t('addMyBookmark')}
					</IonItem>
				)}
				{isAdmin && (
					<IonItem lines="none" button detail onClick={addProjectClick}>
						{t('addProjectBookmark')}
					</IonItem>
				)}
			</IonList>
		</IonContent>
	);
};

const ProjectBookmarkButton: React.FC<ProjectBookmarkButtonProps> = ({
	projectId,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [omegaUser] = useOmegaUser();

	const [presentAdd] = useIonAlert();

	const saveBookmark = (name: string) => {
		if (!omegaUser) {
			return;
		}
		const extra = history.location.search ?? '';
		saveProjectBookmark(projectId, {
			id: nanoid(),
			name,
			path: `${history.location.pathname}${extra}`,
			userId: omegaUser.id,
		});
	};

	const saveAllBookmark = (name: string) => {
		const extra = history.location.search ?? '';
		saveProjectAllBookmark(projectId, {
			id: nanoid(),
			name,
			path: `${history.location.pathname}${extra}`,
			userId: '',
		});
	};

	const addBookmark = () => {
		presentAdd({
			cssClass: 'bookmark-popover',
			header: t('addBookmark'),
			message: t('currentPageToBookmark'),
			inputs: [
				{
					name: 'bookmarkName',
					type: 'text',

					placeholder: t('bookmarkName'),
				},
			],
			buttons: [
				t('cancel'),
				{
					text: t('add'),
					handler: data => {
						if (data.bookmarkName) {
							saveBookmark(data.bookmarkName);
							return true;
						}
						return false;
					},
				},
			],
		});
	};

	const addProjectBookmark = () => {
		presentAdd({
			cssClass: 'bookmark-popover',
			header: t('addProjectBookmark'),
			message: t('currentPageToBookmark'),
			inputs: [
				{
					name: 'bookmarkName',
					type: 'text',

					placeholder: t('bookmarkName'),
				},
			],
			buttons: [
				t('cancel'),
				{
					text: t('add'),
					handler: data => {
						if (data.bookmarkName) {
							saveAllBookmark(data.bookmarkName);
							return true;
						}
						return false;
					},
				},
			],
		});
	};

	const [presentBookmarks, dismiss] = useIonPopover(BookmarkPopover, {
		route: (route: string) => {
			dismiss();
			setTimeout(() => history.push(route), 250);
		},
		projectId: projectId,
		addClick: addBookmark,
		addProjectClick: addProjectBookmark,
	});

	return (
		<>
			<IonButton
				onClick={e =>
					presentBookmarks({ event: e as any, cssClass: 'bookmarks-popover' })
				}
			>
				{t('bookmark')}
			</IonButton>
		</>
	);
};

export default ProjectBookmarkButton;
