import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from './useQuery';

export const useModalOpen = <T>(
	id: string,
): [T | undefined | null, Dispatch<SetStateAction<T | undefined | null>>] => {
	const location = useLocation();
	const nav = useHistory();
	const [state, setState] = useState<T | undefined | null>();
	const query = useQuery();

	useEffect(() => {
		const value = query.get(id);
		if (!value || value !== 'true') {
			setState(undefined);
		}
	}, [id, location, query]);

	useEffect(() => {
		if (
			(typeof state !== 'boolean' && state !== undefined) ||
			(typeof state === 'boolean' && state !== false)
		) {
			query.set(id, 'true');
			if (nav.location.search !== query.toString()) {
				nav.push(`${nav.location.pathname}?${query.toString()}`);
			}
		} else if (query.has(id)) {
			query.delete(id);
			if (nav.location.search !== query.toString()) {
				nav.push(`${nav.location.pathname}?${query.toString()}`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, nav, state]);

	return [state, setState];
};
