import {
	getOrganizationDutiesDocPath,
	getProjectPositionsCollectionPath,
	getProjectTeamCollectionPath,
	ProjectTeamMember,
	ProjectTeamPosition,
	TeamDuties,
} from '@omega/shared';
import {
	collection,
	CollectionReference,
	deleteDoc,
	doc,
	getDoc,
	getDocFromCache,
	getDocFromServer,
	getDocs,
	setDoc,
} from 'firebase/firestore';
import { intersection, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useCollection, useDocument } from 'react-firehooks/firestore';
import { getGenericConverter } from '.';
import { firestoreApp } from '..';
import { useOrganizationState } from '../state';

const projectTeamConverter = getGenericConverter<ProjectTeamPosition>();

const projectTeamMemberConverter = getGenericConverter<ProjectTeamMember>();

const teamDutyConverter = getGenericConverter<TeamDuties>();

function getTeamDutyRef(org: string) {
	return doc(firestoreApp, getOrganizationDutiesDocPath(org)).withConverter(
		teamDutyConverter,
	);
}

export async function getIdsByDuty(projectId: string, duties: string[]) {
	if (duties.length === 0) {
		return [];
	}
	const posDocs = await getDocs(getProjectTeamCollection(projectId));
	return posDocs.docs
		.map(doc => doc.data())
		.filter(data => {
			if (!data.duties) {
				return false;
			}
			return intersection(data.duties, duties).length > 0;
		})
		.map(data => data.id);
}

export function useTeamDuties() {
	const orgState = useOrganizationState();
	const currentOrg = orgState?.get()?.id ?? 'N/A';

	return useDocument(getTeamDutyRef(currentOrg));
}

export function getProjectTeamMemberCollection(
	projectId: string,
): CollectionReference<ProjectTeamMember> {
	return collection(
		firestoreApp,
		getProjectTeamCollectionPath(projectId),
	).withConverter(projectTeamMemberConverter);
}

export function getProjectTeamCollection(
	projectId: string,
): CollectionReference<ProjectTeamPosition> {
	return collection(
		firestoreApp,
		getProjectPositionsCollectionPath(projectId),
	).withConverter(projectTeamConverter);
}

export function useProjectPositions(projectId: string) {
	return useCollection(getProjectTeamCollection(projectId));
}

export async function saveDuties(currentOrg: string, duty: string[]) {
	const docRef = getTeamDutyRef(currentOrg);
	const typesDoc = await getDoc(docRef);
	const duties = typesDoc.data()?.duties ?? [];
	duties.push(...duty);
	return await setDoc(typesDoc.ref, {
		duties: uniq(duties),
	});
}

export function saveProjectPosition(
	orgId: string,
	projectId: string,
	position: ProjectTeamPosition,
) {
	if (position.duties) {
		saveDuties(orgId, position.duties);
	}

	return setDoc<ProjectTeamPosition>(
		doc(getProjectTeamCollection(projectId), position.id),
		position,
	);
}

export function deleteProjectPosition(projectId: string, positionId: string) {
	return deleteDoc(doc(getProjectTeamCollection(projectId), positionId));
}

export async function getCachedProjectPosition(
	projectId: string,
	id: string,
): Promise<ProjectTeamPosition | undefined> {
	try {
		const posDoc = await getDocFromCache(
			doc(getProjectTeamCollection(projectId), id),
		);
		return posDoc.data();
	} catch (ex) {
		const posDoc = await getDocFromServer(
			doc(getProjectTeamCollection(projectId), id),
		);
		return posDoc.data();
	}
}

export function useCachedProjectPosition(projectId: string, id: string) {
	const [loading, setLoading] = useState(true);
	const [position, setPosition] = useState<ProjectTeamPosition>();
	useEffect(() => {
		getCachedProjectPosition(projectId, id).then(pos => {
			setLoading(false);
			setPosition(pos);
		});
	}, [id, projectId]);

	return { position, loading };
}

export function useCachedProjectPositions(projectId: string, ids: string[]) {
	const [loading, setLoading] = useState(true);
	const [positions, setPositions] = useState<ProjectTeamPosition[]>();
	useEffect(() => {
		Promise.all(ids.map(id => getCachedProjectPosition(projectId, id))).then(
			pos => {
				const newPos = pos.filter(Boolean) as ProjectTeamPosition[];
				setPositions(newPos);
				setLoading(false);
			},
		);
	}, [ids, projectId]);

	return { positions, loading };
}
