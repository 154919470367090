import { IonSlide, IonSlides } from '@ionic/react';
import Skeleton from '@mui/material/Skeleton';
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy } from 'pdfjs-dist';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PdfCanvasProps } from './types';

GlobalWorkerOptions.workerSrc =
	'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

const PdfCanvasPage: React.FC<{
	scale: number;
	pageNum: number;
	style?: React.CSSProperties;
	pdfRef: PDFDocumentProxy;
	onPageRenderSuccess?: (canvas: HTMLCanvasElement) => void;
	setLoading: (loading: boolean) => void;
	active: boolean;
}> = ({
	scale,
	pageNum,
	style,
	pdfRef,
	onPageRenderSuccess,
	setLoading,
	active,
}) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [rendered, setRendered] = useState(false);
	const renderPage = useCallback(
		pageNum => {
			!rendered &&
				active &&
				pdfRef &&
				pdfRef.getPage(pageNum).then(page => {
					if (!canvasRef.current) {
						return;
					}
					const viewport = page.getViewport({ scale });
					const canvas = canvasRef.current;
					canvas.height = viewport.height;
					canvas.width = viewport.width;
					const renderContext = {
						canvasContext: canvas.getContext('2d')!,
						viewport: viewport,
					};
					page.render(renderContext).promise.then(
						() => {
							if (onPageRenderSuccess && canvasRef.current) {
								onPageRenderSuccess(canvasRef.current);
							}
							setRendered(true);
							setLoading(false);
						},
						error => {
							console.error(error);

							setLoading(false);
						},
					);
				});
		},
		[active, onPageRenderSuccess, pdfRef, rendered, scale, setLoading],
	);

	useEffect(() => {
		setRendered(false);
	}, [scale]);

	useEffect(() => {
		if (active && !rendered) {
			renderPage(pageNum);
		}
	}, [pdfRef, pageNum, renderPage, active, rendered]);
	return (
		<IonSlide>
			<div className="swiper-zoom-container">
				<canvas className="pdf-canvas" style={style} ref={canvasRef}></canvas>
			</div>
		</IonSlide>
	);
};

const PdfCanvas: React.FC<PdfCanvasProps> = ({
	pdfUrl,
	currentPage,
	onPageRenderSuccess,
	onDocumentLoaded,
	style,
	pageChanged,
}) => {
	const slidesRef = useRef<HTMLIonSlidesElement>(null);
	const [pdfRef, setPdfRef] = useState<PDFDocumentProxy>();
	const [loading, setLoading] = useState(false);
	const [docLoaded, setDocLoaded] = useState(false);
	const [scale, setScale] = useState(1);

	useEffect(() => {
		if (docLoaded) {
			slidesRef.current?.getSwiper().then(swiper => {
				swiper.on('zoomChange', (scale: any) => {
					setScale(scale);
				});
			});
		}
	}, [currentPage, docLoaded]);

	useEffect(() => {
		if (slidesRef.current && docLoaded) {
			slidesRef.current.slideTo(currentPage - 1);
		}
	}, [currentPage, docLoaded]);

	useEffect(() => {
		setLoading(true);
		const loadingTask = getDocument(pdfUrl);
		loadingTask.promise.then(
			loadedPdf => {
				setPdfRef(loadedPdf);
				if (onDocumentLoaded) {
					onDocumentLoaded(loadedPdf);
				}
				setDocLoaded(true);
			},
			function (reason) {
				console.error(reason);
				// TODO ERROR message
				setLoading(false);
			},
		);
	}, [onDocumentLoaded, pdfUrl]);

	return (
		<>
			{loading && (
				<Skeleton
					width={600}
					style={{
						top: -150,
						left: 'calc(50% - 300px)',
						height: '100% important',
						minHeight: 1200,
					}}
					className="absolute"
				/>
			)}
			<IonSlides
				ref={slidesRef}
				onIonSlideDidChange={async () => {
					if (slidesRef.current && pageChanged) {
						pageChanged((await slidesRef.current.getActiveIndex()) + 1);
					}
				}}
				options={{
					zoom: {
						enabled: true,
						toggle: true,
						maxRatio: 4,
						containerClass: 'swiper-zoom-container',
					},
					pagination: {
						type: 'progressbar',
					},
				}}
				className="h-full w-full"
				pager={true}
			>
				{pdfRef &&
					Array.from(Array(pdfRef.numPages).keys()).map(key => (
						<PdfCanvasPage
							scale={scale}
							onPageRenderSuccess={onPageRenderSuccess}
							key={key}
							pageNum={key + 1}
							style={style}
							pdfRef={pdfRef}
							setLoading={setLoading}
							active={currentPage - 1 === key}
						/>
					))}
			</IonSlides>
		</>
	);
};

export default PdfCanvas;
