import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonFooter,
	IonToolbar,
	useIonAlert,
} from '@ionic/react';
import Masonry from '@mui/lab/Masonry';
import { Stack } from '@mui/material';
import { FillableFormUpload, OrganizationRole } from '@omega/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyContent from '../../components/empty-content/EmptyContent';
import ExpandSearch from '../../components/expand-search/ExpandSearch';
import FuseHighlight from '../../components/fuse-highlight/FuseHighlight';
import Page from '../../components/page/Page';
import {
	deleteOrgForm,
	useOrganizationForms,
} from '../../database/fillable-form';
import { useUserHasOrgPrivilege } from '../../database/permissions';
import { useFuse } from '../../hooks/useFuse';
import { useModalOpen } from '../../hooks/useModalOpen';
import EditFillableFormUpload from '../../modals/edit-fillable-form-upload/EditFillableFormUpload';
import { FORM_UPLOADS_ROUTE } from '../../Routes';
import { useOrganizationState } from '../../state';
import { FILLABLE_FORMS_ICON } from '../icons';

const FormUploads: React.FC = () => {
	const { t } = useTranslation();
	const [present] = useIonAlert();

	const orgState = useOrganizationState();
	const currentOrg = orgState?.get()?.id;
	const [forms, loading] = useOrganizationForms(currentOrg ?? 'N/A');

	const [editModalOpen, setEditModalOpen] =
		useModalOpen<FillableFormUpload | null>('form');

	const isContributor = useUserHasOrgPrivilege([
		OrganizationRole.OWNER,
		OrganizationRole.ADMIN,
		OrganizationRole.CONTRIBUTOR,
	]);

	const { hits, query, setQuery } = useFuse(forms, {
		threshold: 0.4,
		includeMatches: true,
		keys: ['name', 'description'],
	});

	const confirmDelete = (formId: string) => {
		if (!currentOrg) {
			return;
		}
		present({
			message: t('confirmDeleteNote'),
			buttons: [
				t('cancel'),
				{
					text: t('delete'),
					handler: async () => {
						deleteOrgForm(currentOrg, formId);
					},
				},
			],
		});
	};

	const renderButtons = () => {
		return (
			<>
				<ExpandSearch
					searchText={query}
					setSearchText={text => setQuery(text)}
				></ExpandSearch>
				<IonButton
					disabled={!isContributor}
					onClick={() => setEditModalOpen(null)}
					fill="clear"
				>
					{t('new')}
				</IonButton>
			</>
		);
	};

	const renderEmpty = () => {
		return (
			<EmptyContent
				title={t('noFormUploadsFound')}
				subTitle={t('adjustFiltersOrCreateFormUpload')}
				actionText={t('createNewForm')}
				actionClick={() => setEditModalOpen(null)}
			/>
		);
	};

	return (
		<>
			<EditFillableFormUpload
				onClose={() => setEditModalOpen(undefined)}
				isOpen={editModalOpen !== undefined}
				edit={editModalOpen}
			/>

			<Page
				buttons={renderButtons()}
				icon={FILLABLE_FORMS_ICON}
				loading={loading}
				breadcrumbs={[{ name: t('formUploads'), path: FORM_UPLOADS_ROUTE }]}
			>
				{!hits.length && renderEmpty()}
				<Masonry columns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }} spacing={0}>
					{hits.map(hit => {
						return (
							<Stack key={hit.item.id}>
								<IonCard
									className="cursor-pointer"
									onClick={() => setEditModalOpen(hit.item)}
								>
									<IonCardHeader className={`basic-card-header`}>
										<IonCardTitle className="basic-card-title">
											<FuseHighlight hit={hit} attribute="name" />
										</IonCardTitle>
									</IonCardHeader>
									<IonCardContent>
										<table className="w-full m-2">
											<tbody>
												<tr>
													<td>
														<b className="card-item-entry">
															{t('description')}
														</b>
														<div>
															<FuseHighlight
																className="inline"
																hit={hit}
																attribute="description"
															/>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</IonCardContent>
									<IonFooter>
										<IonToolbar>
											<IonButtons slot="start">
												<IonButton
													onClick={e => {
														e.stopPropagation();
														e.preventDefault();
														confirmDelete(hit.item.id);
													}}
													color="danger"
												>
													{t('delete')}
												</IonButton>
											</IonButtons>
											<IonButtons slot="end">
												<IonButton onClick={() => setEditModalOpen(hit.item)}>
													{t('edit')}
												</IonButton>
											</IonButtons>
										</IonToolbar>
									</IonFooter>
								</IonCard>
							</Stack>
						);
					})}
				</Masonry>
			</Page>
		</>
	);
};

export default FormUploads;
