import { PROJECTS_COLLECTION } from './Project';

export interface PunchItem {
	id: string;
	name: string;
	description: string;
	location?: string;
	created: Date;
	updated: Date;
	status: PunchItemStatus;
	userId: string;
	category: PunchItemCategory;
}

export enum PunchItemStatus {
	DRAFT = 'DRAFT',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
	CANCELLED = 'CANCELLED',
	PENDING_APPROVED = 'PENDING_APPROVED',
	APPROVED = 'APPROVED',
}

export const PUNCH_ITEM_COLLECTION = 'punch-items';
export const PUNCH_ITEM_NOTES_COLLECTION = 'notes';

export enum PunchItemCategory {
	SOLAR_AC = 'SOLAR_AC',
	SOLAR_DC = 'SOLAR_DC',
	DRAWINGS = 'DRAWINGS',
	RACKING = 'RACKING',
	ELECTRICAL = 'ELECTRICAL',
	ROOFING = 'ROOFING',
	ARCHITECTURAL = 'ARCHITECTURAL',
	CIVIL = 'CIVIL',
	CONVEYING_SYSTEM = 'CONVEYING_SYSTEM',
	INTERIOR_DESIGN = 'INTERIOR_DESIGN',
	KITCHEN_EQUIPMENT = 'KITCHEN_EQUIPMENT',
	LANDSCAPE = 'LANDSCAPE',
	MECHANICAL = 'MECHANICAL',
	OTHER = 'OTHER',
	PLUMBING = 'PLUMBING',
	STRUCTURAL = 'STRUCTURAL',
}

export const getProjectPunchItemsCollection = (projectId: string) =>
	`${PROJECTS_COLLECTION}/${projectId}/${PUNCH_ITEM_COLLECTION}`;

// projects/:projectId/punch-items/:punchItemId/notes
export const getProjectPunchItemNotesCollection = (
	projectId: string,
	punchItemId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${PUNCH_ITEM_COLLECTION}/${punchItemId}/${PUNCH_ITEM_NOTES_COLLECTION}/`;
