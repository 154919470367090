import Fuse from 'fuse.js';
import React from 'react';
import { FuseHighlightProps } from './types';

interface HighlightProps {
	value: string;
	indices?: Fuse.RangeTuple[];
	i?: number;
}

// Finds `obj[path][to][key]` from `path.to.key`
const resolveAttribute = (obj: any, key: string) =>
	key.split('.').reduce((prev, curr) => prev?.[curr], obj);

// Recursively builds TSX output adding `<mark>` tags around matches
const Highlight: React.FC<HighlightProps> = ({
	value,
	indices = [],
	i = 1,
}) => {
	const pair = indices[indices.length - i];
	return !pair ? (
		<>{value}</>
	) : (
		<>
			<Highlight
				value={value.substring(0, pair[0])}
				indices={indices}
				i={i + 1}
			/>
			<mark>{value.substring(pair[0], pair[1] + 1)}</mark>
			{value.substring(pair[1] + 1)}
		</>
	);
};

// FuseHighlight component
function FuseHighlight<T>({
	hit,
	attribute,
	className,
}: FuseHighlightProps<T>) {
	const matches =
		typeof hit.item === 'string'
			? hit.matches?.[0]
			: hit.matches?.find(m => {
					let findAttr: string = attribute;
					if (findAttr.includes('.')) {
						const split = findAttr.split('.');
						findAttr = split[0];
						return m.key === findAttr && `${m.refIndex}` === split[1];
					}

					return m.key === findAttr;
			  });
	const fallback =
		typeof hit.item === 'string'
			? hit.item
			: resolveAttribute(hit.item, attribute as string) ?? '';
	return (
		<div className={className}>
			<Highlight
				value={matches?.value || fallback}
				indices={matches?.indices as Fuse.RangeTuple[]}
			/>
		</div>
	);
}

export default FuseHighlight;
