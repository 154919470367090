import { IonButtons, IonHeader, IonIcon } from '@ionic/react';
import Masonry from '@mui/lab/Masonry';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { motion } from 'framer-motion';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { PROJECT_PREFIX } from '../../Routes';
import useWindowDimensions from '../../util/useWindowDimensions';
import ErrorBoundary from '../error-boundary/ErrorBoundary';
import ProjectBookmarkButton from '../project-bookmark/ProjectBookmarkButton';
import './style.css';
import { PageProps } from './types';

const Page: React.FC<PageProps> = ({
	breadcrumbs,
	children,
	buttons,
	loading,
	icon,
	hideBreadcrumbs,
}) => {
	const { width } = useWindowDimensions();
	const history = useHistory();
	const { id } = useParams<{ id?: string }>();

	const location = history.location.pathname;
	let projectId: string | undefined = undefined;
	if (location.includes(PROJECT_PREFIX)) {
		projectId = id;
	}

	const renderBreadCrumbs = () => {
		if (loading) {
			return (
				<Skeleton
					style={{ marginTop: 10 }}
					variant="rectangular"
					width={350}
					height={40}
				/>
			);
		}
		return (
			<Breadcrumbs maxItems={width > 600 ? 8 : 3}>
				{breadcrumbs.map((crumb, index) => {
					return (
						<Link
							key={index}
							component={RouterLink as any}
							color="initial"
							className={
								index === breadcrumbs.length - 1
									? 'active-breadcrumb breadcrumb'
									: 'breacrumb'
							}
							underline="hover"
							to={crumb.path}
						>
							{crumb.name}
						</Link>
					);
				})}
			</Breadcrumbs>
		);
	};

	const renderLoadingSkeleton = () => {
		return (
			<div className="loading-container">
				<Masonry columns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }} spacing={0}>
					<Stack>
						<Skeleton
							className="loading-page-rect"
							variant="rectangular"
							height={300}
						/>
					</Stack>
					<Stack>
						<Skeleton
							className="loading-page-rect"
							variant="rectangular"
							height={300}
						/>
					</Stack>
					<Stack>
						<Skeleton
							className="loading-page-rect"
							variant="rectangular"
							height={300}
						/>
					</Stack>
				</Masonry>
			</div>
		);
	};

	return (
		<motion.div
			initial="initial"
			animate="in"
			exit="out"
			transition={{ delayChildren: 1 }}
			variants={{
				initial: {
					opacity: 0,
				},
				in: {
					opacity: 1,
				},
				out: {
					opacity: 0,
				},
			}}
			className="page-root"
		>
			{!hideBreadcrumbs && (
				<IonHeader mode="md" translucent className="page-header">
					<div className="page-toolbar">
						{typeof icon === 'string' ? (
							<IonIcon className="page-icon" icon={icon as any}></IonIcon>
						) : (
							<div className="page-icon">{icon}</div>
						)}

						<div className="page-toolbar-content">
							<div className="page-toolbar-title">{renderBreadCrumbs()}</div>
							<IonButtons className="page-toolbar-buttons">
								{buttons}
								{projectId && <ProjectBookmarkButton projectId={projectId} />}
							</IonButtons>
						</div>
					</div>
				</IonHeader>
			)}
			<div className="page-container">
				{loading ? renderLoadingSkeleton() : children}
			</div>
		</motion.div>
	);
};

const SafePage: React.FC<PageProps> = props => {
	return (
		<ErrorBoundary>
			<Page {...props} />
		</ErrorBoundary>
	);
};

export default SafePage;
