import { ORGANIZATIONS_COLLECTION } from './Organization';
import { PROJECTS_COLLECTION } from './Project';

export const FILLABLE_FORM_COLLECTION = 'fillable-forms';

export const getOrganizationFillableFormPath = (
	organizationId: string,
	formId: string,
	fileName: string,
) => {
	return `${ORGANIZATIONS_COLLECTION}/${organizationId}/${FILLABLE_FORM_COLLECTION}/${formId}/${fileName}`;
};

export const getProjectFillableFormFile = (
	projectId: string,
	drawingId: string,
	drawing: string,
) => {
	return `${PROJECTS_COLLECTION}/${projectId}/${FILLABLE_FORM_COLLECTION}/${drawingId}/${drawing}`;
};

/**
 * Returns the collection reference for an organizations fillable forms.
 * @param organizationId to look up the fillable forms
 * @returns the string of the org's fillable forms.
 */
export const getFillableFormUploadCollectionString = (organizationId: string) =>
	`${ORGANIZATIONS_COLLECTION}/${organizationId}/${FILLABLE_FORM_COLLECTION}`;

/**
 * Returns the collection reference for an projects fillable forms.
 * @param projectId to look up the fillable forms
 * @returns the string of the org's fillable forms.
 */
export const getUserFillableFormUploadCollectionString = (projectId: string) =>
	`${PROJECTS_COLLECTION}/${projectId}/${FILLABLE_FORM_COLLECTION}`;

export interface ProjectFillableForm {
	/**
	 * Id of the form.
	 */
	id: string;
	/**
	 * Name of the fillable form.
	 */
	name: string;
	/**
	 * Description of the form.
	 */
	description?: string;
	/**
	 * Link to the pdf form
	 */
	file: string;
	/**
	 * When the revision was made
	 */
	timestamp: Date;
	/**
	 * User who made the change.
	 */
	userId: string;
	/**
	 * Thumbnail image
	 */
	thumbnail: string;
	/**
	 * Metadata
	 */
	metadata: ProjectFillableFormMetadata;
}

export interface ProjectFillableFormMetadata {
	[key: string]: any;
}

/**
 * List of all forms associated to an organization
 */
export interface FillableFormUpload {
	/**
	 * Id of the fillable form upload.
	 */
	id: string;
	/**
	 * Name of the form.
	 */
	name: string;
	/**
	 * Description of the form
	 */
	description?: string;
	/**
	 * Link to the uploaded pdf
	 */
	file: string;
	/**
	 * Preview image of the pdf.
	 */
	thumbnail: string;
}
