import { OrganizationRole, ProjectRole } from '@omega/shared';
import { doc } from 'firebase/firestore';
import { useDocument } from 'react-firehooks/firestore';
import { useAuthState } from 'react-firehooks/auth';
import { firebaseAuth } from '..';
import { useOrganizationState } from '../state';
import { getProjectTeamMemberCollection } from './project-team';
import { useOrganizationMembers } from './organization';

export const useUserHasPrivilege = (
	orgRoles: OrganizationRole[],
	projectRoles: ProjectRole[],
	projectId: string,
) => {
	const orgState = useOrganizationState();
	const [user] = useAuthState(firebaseAuth);
	const userId = user?.uid ?? 'NAN';
	const orgId = orgState.value?.id ?? 'NAN';
	const [memberDoc] = useProjectTeamMember(projectId, userId);

	const [orgMemberDocs] = useOrganizationMembers(orgId);
	const currentProjectRoles = memberDoc?.data()?.effectiveRoles ?? [];

	const orgDoc = orgMemberDocs?.docs.find(doc => {
		const data = doc.data();
		return data.id === userId;
	});
	const currentOrgRoles = orgDoc?.data().roles ?? [];
	return (
		currentOrgRoles.some(role => orgRoles.includes(role)) ||
		currentProjectRoles.some(role => projectRoles.includes(role))
	);
};

export const useUserHasOrgPrivilege = (
	orgRoles: OrganizationRole[],
): [boolean, boolean] => {
	const orgState = useOrganizationState();
	const [user, userLoading] = useAuthState(firebaseAuth);
	const userId = user?.uid ?? 'NAN';
	const orgId = orgState.value?.id ?? 'NAN';
	const [orgMemberDocs, orgLoading] = useOrganizationMembers(orgId);
	const orgDoc = orgMemberDocs?.docs.find(doc => {
		const data = doc.data();
		return data.id === userId;
	});
	const currentOrgRoles = orgDoc?.data().roles ?? [];
	return [
		currentOrgRoles.some(role => orgRoles.includes(role)),
		userLoading && orgLoading,
	];
};

export function useProjectTeamMember(projectId: string, userId: string) {
	return useDocument(doc(getProjectTeamMemberCollection(projectId), userId));
}
