import {
	CollectionReference,
	DocumentReference,
	getDoc,
	getDocFromCache,
	getDocFromServer,
	query,
	QueryConstraint,
	updateDoc,
	where,
} from '@firebase/firestore';
import {
	getOmegaUserOrganizationCollectionPath,
	getProjectLayoutCollectionPath,
	OmegaUser,
	OmegaUserOrganization,
	OMEGA_USER_COLLECTION,
	ProjectLayout,
	ProjectLayoutType,
} from '@omega/shared';
import { collection, doc, orderBy, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useCollection, useDocument } from 'react-firehooks/firestore';
import { getGenericConverter } from '.';
import { firebaseAuth, firestoreApp } from '..';
import { RecursivePartial } from '../util/recursivePartial';

const userConverter = getGenericConverter<OmegaUser>();
const userOrgConverter = getGenericConverter<OmegaUserOrganization>();
const projectLayoutConverter = getGenericConverter<ProjectLayout>();

export const DEFAULT_PROJECT_LAYOUT: ProjectLayout[] = [
	{
		position: 0,
		type: ProjectLayoutType.DETAIL,
		enabled: true,
	},
	{
		position: 1,
		type: ProjectLayoutType.WEATHER,
		enabled: true,
	},
	{
		position: 2,
		type: ProjectLayoutType.FIELD_MANAGEMENT,
		enabled: true,
	},
	{
		position: 3,
		type: ProjectLayoutType.DRAWINGS_SPECS,
		enabled: true,
	},
	{
		position: 4,
		type: ProjectLayoutType.CHECKLIST_COMPLETED,
		enabled: true,
	},
	{
		position: 5,
		type: ProjectLayoutType.MILESTONES,
		enabled: true,
	},
	{
		position: 6,
		type: ProjectLayoutType.RFI,
		enabled: true,
	},
];

/**
 * Get the omega user collection
 * @returns  the firestore omega user collection
 */
export function getOmegaUserProjectLayoutCollection(
	userId: string,
): CollectionReference<ProjectLayout> {
	return collection(
		firestoreApp,
		getProjectLayoutCollectionPath(userId),
	).withConverter(projectLayoutConverter);
}

export function saveProjectLayout(userId: string, layout: ProjectLayout) {
	return setDoc<ProjectLayout>(
		doc<ProjectLayout>(
			getOmegaUserProjectLayoutCollection(userId),
			layout.type,
		),
		layout,
	);
}

export function useProjectLayout(enabled?: boolean) {
	const userId = firebaseAuth.currentUser?.uid ?? 'N/A';

	const queryConstraints: QueryConstraint[] = [orderBy('position', 'asc')];

	if (enabled !== undefined) {
		queryConstraints.push(where('enabled', '==', enabled));
	}
	const [layouts, loading] = useCollection(
		query(getOmegaUserProjectLayoutCollection(userId), ...queryConstraints),
	);
	if (layouts === undefined && loading === true) {
		return undefined;
	}
	if (layouts?.empty) {
		DEFAULT_PROJECT_LAYOUT.forEach(layout => {
			saveProjectLayout(userId, layout);
		});
	}

	if (layouts && layouts.docs.length < DEFAULT_PROJECT_LAYOUT.length) {
		const layoutDocs = layouts.docs.map(doc => doc.data());
		DEFAULT_PROJECT_LAYOUT.forEach(defaultLayout => {
			const found = layoutDocs.find(doc => doc.type === defaultLayout.type);
			if (!found) {
				saveProjectLayout(userId, defaultLayout);
			}
		});
	}

	return !layouts || layouts.empty
		? DEFAULT_PROJECT_LAYOUT
		: layouts.docs.map(doc => doc.data());
}

/**
 * Save the omega user
 * @param omegaUser to save
 * @returns the promise of the save
 */
export const saveOmegaUser = (omegaUser: OmegaUser) => {
	const collection = getOmegaUserCollection();
	const toSave = {
		...omegaUser,
		created: omegaUser.created ?? new Date(),
		updated: new Date(),
	};
	return setDoc<OmegaUser>(doc<OmegaUser>(collection, toSave.id), toSave);
};

export async function getCachedOmegaUser(
	id: string,
): Promise<OmegaUser | undefined> {
	try {
		const userDoc = await getDocFromCache(doc(getOmegaUserCollection(), id));
		return userDoc.data();
	} catch (ex) {
		const userDoc = await getDocFromServer(doc(getOmegaUserCollection(), id));
		return userDoc.data();
	}
}

export function useCachedOmegaUser(id?: string) {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState<OmegaUser>();
	const [authUser] = useAuthState(firebaseAuth);

	const idToUse = id ?? authUser?.uid;

	useEffect(() => {
		if (!idToUse) {
			return;
		}
		getCachedOmegaUser(idToUse).then(user => {
			setLoading(false);
			setUser(user);
		});
	}, [idToUse]);

	return { user, loading };
}

export function useCacheUsers(ids: string[]) {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState<OmegaUser[]>();
	useEffect(() => {
		Promise.all(ids.map(id => getCachedOmegaUser(id))).then(pos => {
			const newPos = pos.filter(Boolean) as OmegaUser[];
			setUsers(newPos);
			setLoading(false);
		});
	}, [ids]);

	return { users, loading };
}

export function getOmegaUser(id?: string): DocumentReference<OmegaUser> | null {
	if (!id) {
		return firebaseAuth.currentUser?.uid
			? doc(getOmegaUserCollection(), firebaseAuth.currentUser?.uid)
			: null;
	}
	return doc(getOmegaUserCollection(), id);
}

/**
 * Get the omega user collection
 * @returns  the firestore omega user collection
 */
export function getOmegaUserCollection(): CollectionReference<OmegaUser> {
	return collection(firestoreApp, OMEGA_USER_COLLECTION).withConverter(
		userConverter,
	);
}

/**
 * Get the omega user org collection
 * @returns  the firestore omega user collection
 */
export function getOmegaUserOrganizationCollection(
	uid?: string,
): CollectionReference<OmegaUserOrganization> | null {
	if (!uid) {
		return null;
	}

	return collection(
		firestoreApp,
		getOmegaUserOrganizationCollectionPath(uid),
	).withConverter(userOrgConverter);
}

/**
 * Gets the portfolio with the given id.
 * @returns document result for the given id
 */
export function useOmegaUser(id?: string) {
	const [user] = useAuthState(firebaseAuth);

	return useDocument(getOmegaUser(id ?? user?.uid));
}

/**
 * Gets the portfolio with the given id.
 * @returns document result for the given id
 */
export function useOmegaUserOrganizations() {
	const [user] = useAuthState(firebaseAuth);
	return useCollection(getOmegaUserOrganizationCollection(user?.uid));
}

export async function updateOmegaUser(partial: RecursivePartial<OmegaUser>) {
	const ref = getOmegaUser(firebaseAuth.currentUser?.uid);
	if (!ref) {
		return false;
	}
	await updateDoc(ref, partial);
	return true;
}

export async function getOmegaUserDoc(id: string) {
	return getDoc(doc(getOmegaUserCollection(), id));
}
