import { IonButton, IonIcon, IonSearchbar } from '@ionic/react';
import { AnimatePresence, motion } from 'framer-motion';
import { searchOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import './style.css';
import { ExpandSearchProps } from './types';

const ExpandSearch: React.FC<ExpandSearchProps> = ({
	searchText,
	setSearchText,
}) => {
	const [open, setOpen] = useState(false);

	const onIconClick = () => {
		setOpen(true);
	};

	useEffect(() => {
		setTimeout(
			() => (document.querySelector('.searchbar-input') as any)?.focus(),
			100,
		);
	}, [open]);

	const onBlur = () => {
		if (!searchText) {
			setOpen(false);
		}
	};

	return (
		<AnimatePresence presenceAffectsLayout exitBeforeEnter initial={false}>
			{open ? (
				<motion.div
					className="expand-search-bar"
					key="searchbar"
					initial={{ width: 36, opacity: 0 }}
					animate={{ width: '80%', opacity: 1 }}
					exit={{ width: 36, height: 32, opacity: 0 }} // exit not working
				>
					<IonSearchbar
						id="project-search"
						onIonBlur={onBlur}
						value={searchText}
						onIonChange={e => setSearchText(e.detail.value)}
					></IonSearchbar>
				</motion.div>
			) : (
				<motion.div
					className="expand-collapsed"
					animate={{ width: 36, opacity: 1 }}
					initial={{ width: 36, height: 32, opacity: 0 }}
					key="icon"
				>
					<IonButton fill='clear' className="expand-search-button" onClick={onIconClick}>
						<IonIcon icon={searchOutline} />
					</IonButton>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default ExpandSearch;
