import { PROJECTS_COLLECTION } from './Project';
import { ProjectItemStatus } from './Status';

export const ASSIGNMENT_COLLECTION = 'assignments';

export enum AssignmentType {
	CHECKLIST = 'CHECKLIST',
	DRAWING = 'DRAWING',
	RFI = 'RFI',
	PUNCH_ITEM = 'PUNCH_ITEM',
	ADHOC = 'ADHOC',
	OBSERVATION = 'OBSERVATION',
}

export const getProjectAssignmentCollection = (projectId: string) =>
	`${PROJECTS_COLLECTION}/${projectId}/${ASSIGNMENT_COLLECTION}`;

export const getIdFromPath = (path: string) => {
	const lastIndex = path.lastIndexOf('/') + 1;
	return path.substring(lastIndex);
};
export interface Assignment {
	id: string;
	/**
	 * The organization id associated to the assignment;
	 */
	projectId: string;
	/**
	 * The organization id associated to the assignment;
	 */
	organizationId: string;
	/**
	 * The users assigned to this assignment
	 */
	userIds: string[];
	/**
	 * The project positions that are assigned to this assignment
	 */
	positionIds: string[];
	/**
	 * The userId who approved this assignment.
	 */
	approvedBy?: string;
	/**
	 * The user's name who approved the assignment.
	 */
	approvedByUserName?: string;
	/**
	 * Flag to indicate if approval is required to complete the assignment.
	 */
	requiresApproval: boolean;
	/**
	 * The status of this assignment
	 */
	status: ProjectItemStatus;
	/**
	 * The type of assignment.
	 */
	type: AssignmentType;
	/**
	 * The document path to the associated item.
	 */
	associatedPath?: string;
	/**
	 * Title of the assignment
	 */
	title?: string;
	/**
	 * Description of the assignment
	 */
	description?: string;
	/**
	 * The date the assignment was created.
	 */
	created: Date;
	/**
	 * The user who created the assignment.
	 */
	createdBy: string;
	/**
	 * The date the assignment is due.
	 */
	dueDate?: Date;
	/**
	 * The date the assignment was finished.
	 */
	finished?: Date;
	/**
	 * The additional list of users to notify
	 */
	notifyIds: string[];
	/**
	 * The additional list of positions to notify.
	 */
	notifyPositionIds: string[];
	/**
	 * The user who completed the assignment
	 */
	completedByUserName?: string;
	/**
	 * The user who completed the assignment.
	 */
	completedByUserId?: string;
	/**
	 * The approver user ids.
	 */
	approverIds?: string[];

	/**
	 * The positions to approve the assignment.
	 */
	approverPositionIds?: string[];
	/**
	 * Last user id to make an update
	 */
	lastUpdateUserId?: string;
	/**
	 * Last user to make an update
	 */
	lastUpdateUserName?: string;
}
