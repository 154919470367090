import { IonButton, IonIcon } from '@ionic/react';
import { warningOutline } from 'ionicons/icons';
import './style.css';
import { EmptyContentProps } from './types';

const EmptyContent: React.FC<EmptyContentProps> = ({
	actionClick,
	actionText,
	title,
	subTitle,
	disabled,
}) => {
	return (
		<div className="empty-container">
			<div className="empty-image-container">
				<IonIcon size="large" icon={warningOutline} />
			</div>
			<div className="empty-title">{title}</div>
			<div className="empty-sub">{subTitle}</div>
			{(!!actionText || !!actionClick) && (
					<div className="empty-divider"></div>
				) && (
					<div className="empty-sub">
						<IonButton disabled={disabled} onClick={actionClick} size="small">
							{actionText}
						</IonButton>
					</div>
				)}
		</div>
	);
};

export default EmptyContent;
