import { createState, useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { ProjectStatus } from '@omega/shared';

const PROJECT_FILTER_KEY = 'project-filter';

export interface ProjectFilters {
	searchText?: string;
	portfolioIds?: string[];
	status?: ProjectStatus[];
}

const state = createState<ProjectFilters>({
	status: [
		ProjectStatus.PRECONSTRUCTION,
		ProjectStatus.CLOSE_OUT,
		ProjectStatus.CONSTRUCTION,
	],
});
state.attach(Persistence(PROJECT_FILTER_KEY));

export const useProjectFilterState = () => {
	return useState<ProjectFilters>(state);
};
