import {
	CreatedOmegaUser,
	OrganizationInvite,
	OrganizationInviteStatus,
	ORGANIZATION_INVITE_COLLECTION,
} from '@omega/shared';
import {
	collection,
	CollectionReference,
	doc,
	DocumentReference,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { TFunction } from 'i18next';
import { useAuthState } from 'react-firehooks/auth';
import { useCollection, UseCollectionResult } from 'react-firehooks/firestore';
import { getGenericConverter } from '.';
import { firebaseAuth, firebaseFunctions, firestoreApp } from '..';

const inviteConverter = getGenericConverter<OrganizationInvite>();

export function getOrganizationInviteDoc(
	id?: string,
): DocumentReference<OrganizationInvite> {
	return doc(getOrganizationInviteCollection(), id);
}

/**
 * Get the omega user collection
 * @returns  the firestore omega user collection
 */
export function getOrganizationInviteCollection(): CollectionReference<OrganizationInvite> {
	return collection(firestoreApp, ORGANIZATION_INVITE_COLLECTION).withConverter(
		inviteConverter,
	);
}

/**
 * Gets the OrganizationInvites with associated email
 * @returns document result for the given id
 */
export function useOrganizationPendingInvites(
	orgId: string,
): UseCollectionResult<OrganizationInvite> {
	const queriedInvites = query(
		getOrganizationInviteCollection(),
		where('organizationId', '==', orgId),
		where('status', '==', OrganizationInviteStatus.PENDING),
	);
	return useCollection(queriedInvites);
}

/**
 * Gets the OrganizationInvites with associated email
 * @returns document result for the given id
 */
export function useOrganizationUserInvite(): UseCollectionResult<OrganizationInvite> {
	const [user] = useAuthState(firebaseAuth);
	const queriedInvites = query(
		getOrganizationInviteCollection(),
		where('email', '==', user?.email ?? '_NOT_EMAIL_'),
		where('status', '==', OrganizationInviteStatus.PENDING),
	);
	return useCollection(queriedInvites);
}

export const getDeclineInvite = () =>
	httpsCallable<string>(firebaseFunctions, 'apiInvite-default-declineInvite');

export const getAcceptInvite = () =>
	httpsCallable<string>(firebaseFunctions, 'apiInvite-default-acceptInvite');

export const getCreateInvite = () =>
	httpsCallable<OrganizationInvite>(
		firebaseFunctions,
		'apiInvite-default-createInvite',
	);

export const getCreateUser = () =>
	httpsCallable<CreatedOmegaUser>(
		firebaseFunctions,
		'apiInvite-default-createUser',
	);

export const getCreatedUserInvite = () =>
	httpsCallable<OrganizationInvite>(
		firebaseFunctions,
		'apiInvite-default-createdUserInvite',
	);

export const getCreateInviteEmail = () =>
	httpsCallable<OrganizationInvite>(
		firebaseFunctions,
		'apiInvite-default-createInvite',
	);

export const createInvite = async (
	invite: OrganizationInvite,
	t: TFunction,
) => {
	// lets make sure an invite doesn't already exist
	const snap = await getDocs(
		query(
			getOrganizationInviteCollection(),
			where('email', '==', invite.email),
			where('organizationId', '==', invite.organizationId),
			where('status', '==', OrganizationInviteStatus.PENDING),
		),
	);
	if (!snap.empty) {
		throw new Error(t('userInviteAlreadyExists'));
	}
	return setDoc<OrganizationInvite>(
		doc(getOrganizationInviteCollection(), invite.id),
		invite,
	);
};

export const updateOrganizationInviteStatus = async (
	id: string,
	status: OrganizationInviteStatus,
) => {
	return updateDoc<OrganizationInvite>(
		doc(getOrganizationInviteCollection(), id),
		{
			status,
		},
	);
};
