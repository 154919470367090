import { Downgraded } from '@hookstate/core';
import {
	getProjectBookmarkCollectionPath,
	getProjectFileImagePath,
	Project,
	ProjectBookmark,
	ProjectStatus,
	PROJECTS_COLLECTION,
	ProjectTeamMember,
} from '@omega/shared';
import {
	collection,
	CollectionReference,
	deleteDoc,
	doc,
	DocumentReference,
	getDoc,
	getDocs,
	limit,
	query,
	QueryConstraint,
	QueryDocumentSnapshot,
	setDoc,
	updateDoc,
	where,
} from 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useCollection, useDocument } from 'react-firehooks/firestore';
import { useDownloadURL } from 'react-firehooks/storage';
import usePromise from 'react-use-promise';
import { getGenericConverter, getMultipleConstraints } from '.';
import { firebaseAuth, firestoreApp } from '..';
import { useOrganizationState } from '../state';
import { useProjectFilterState } from '../state/project-filter-state';
import { getProjectTeamMemberCollection } from './project-team';

const projectConverter = getGenericConverter<Project>();
const projectBookmarkConverter = getGenericConverter<ProjectBookmark>();

const ALL_BOOKMARKS = 'ALL';

export function getProjectDoc(id?: string): DocumentReference<Project> {
	return doc(getProjectCollection(), id);
}

export function getProjectImageRef(id: string) {
	const storage = getStorage();
	return ref(storage, getProjectFileImagePath(id));
}

export function useProjectImage(id: string, hasImage?: boolean) {
	const imageRef = hasImage ? getProjectImageRef(id) : null;
	return useDownloadURL(imageRef);
}

export function getProjectBookmarkCollection(
	projectId: string,
): CollectionReference<ProjectBookmark> {
	return collection(
		firestoreApp,
		getProjectBookmarkCollectionPath(projectId),
	).withConverter(projectBookmarkConverter);
}

export function saveProjectBookmark(
	projectId: string,
	projectBookmark: ProjectBookmark,
) {
	const toSave: ProjectBookmark = {
		...projectBookmark,
		id: projectBookmark.id ?? nanoid(),
	};
	return setDoc<ProjectBookmark>(
		doc(getProjectBookmarkCollection(projectId), projectBookmark.id),
		toSave,
	);
}

export function deleteProjectBookmark(projectId: string, id: string) {
	return deleteDoc(doc(getProjectBookmarkCollection(projectId), id));
}

export function useProjectBookmarks(projectId: string) {
	const [authUser] = useAuthState(firebaseAuth);
	return useCollection(
		query(
			getProjectBookmarkCollection(projectId),
			where('userId', '==', authUser?.uid ?? 'N/A'),
		),
	);
}

export function useProjectAllBookmarks(projectId: string) {
	return useCollection(
		query(
			getProjectBookmarkCollection(projectId),
			where('userId', '==', ALL_BOOKMARKS),
		),
	);
}

export function saveProjectAllBookmark(
	projectId: string,
	projectBookmark: ProjectBookmark,
) {
	const toSave: ProjectBookmark = {
		...projectBookmark,
		userId: ALL_BOOKMARKS,
		id: projectBookmark.id ?? nanoid(),
	};
	return setDoc<ProjectBookmark>(
		doc(getProjectBookmarkCollection(projectId), projectBookmark.id),
		toSave,
	);
}

/**
 * Get the omega user collection
 * @returns  the firestore omega user collection
 */
export function getProjectCollection(): CollectionReference<Project> {
	return collection(firestoreApp, PROJECTS_COLLECTION).withConverter(
		projectConverter,
	);
}

export function usePortfolioProjects(portfolioId: string, limitNumber: number) {
	const currentOrg = useOrganizationState().value;
	const [org, setOrg] = useState('NULL');
	useEffect(() => {
		if (currentOrg?.id) {
			setOrg(currentOrg.id);
		}
	}, [currentOrg?.id]);

	const queryConstraints: QueryConstraint[] = [
		where('organizationId', '==', org),
	];
	queryConstraints.push(where('portfolioId', '==', portfolioId));

	if (limitNumber) {
		queryConstraints.push(limit(limitNumber));
	}
	return useCollection(query(getProjectCollection(), ...queryConstraints));
}

export interface MyTeamProject {
	project: QueryDocumentSnapshot<Project>;
	team: ProjectTeamMember;
}
export function useMyProjects() {
	const [authUser] = useAuthState(firebaseAuth);
	const currentOrg = '' + useOrganizationState().value?.id ?? '';
	return usePromise(async () => {
		const queryConstraints: QueryConstraint[] = [
			where('organizationId', '==', currentOrg),
		];
		queryConstraints.push(
			where('status', 'in', [
				ProjectStatus.PRECONSTRUCTION,
				ProjectStatus.CONSTRUCTION,
				ProjectStatus.CLOSE_OUT,
			]),
		);
		const docs = await getDocs(
			query(getProjectCollection(), ...queryConstraints),
		);

		const projects = (
			await Promise.all(
				docs.docs.map(async document => {
					const ref = await getDoc(
						doc(
							getProjectTeamMemberCollection(document.id),
							authUser?.uid ?? 'N/A',
						),
					);
					if (ref.exists()) {
						return {
							project: document,
							team: ref.data(),
						};
					}
					return null;
				}),
			)
		).filter(Boolean);

		return projects as MyTeamProject[];
	}, [authUser?.uid ?? '', currentOrg]);
}

export function useProjects(limitBy: number = 50) {
	const currentOrg = useOrganizationState().value;
	const filterState = useProjectFilterState();
	filterState.attach(Downgraded);

	return usePromise(() => {
		const baseConstraints: QueryConstraint[] = [
			where('organizationId', '==', currentOrg?.id),
			limit(limitBy),
		];

		const queryConstraints: QueryConstraint[][] = [];

		if (filterState.portfolioIds?.value?.length) {
			queryConstraints.push([
				...baseConstraints,
				where('portfolioId', 'in', filterState?.value?.portfolioIds),
			]);
		}
		if (filterState.status?.value?.length) {
			queryConstraints.push([
				...baseConstraints,
				where('status', 'in', filterState?.value?.status),
			]);
		}
		if (!queryConstraints.length) {
			queryConstraints.push([...baseConstraints]);
		}
		return getMultipleConstraints(getProjectCollection(), queryConstraints);
	}, [filterState?.portfolioIds.value, filterState?.status.value]);
}
/**
 * Gets the project with the given id.
 * @returns document result for the given id
 */
export function useProject(id?: string) {
	return useDocument(getProjectDoc(id));
}

/**
 *
 * @param project
 * @returns
 */
export function saveProject(project: Project) {
	if (!project.id) {
		project.id = nanoid();
	}
	const toSave: Project = {
		...project,
		id: project.id ?? nanoid(),
	};
	return setDoc<Project>(doc(getProjectCollection(), project.id), toSave);
}

export function updateProject(project: Partial<Project>) {
	return updateDoc<Project>(doc(getProjectCollection(), project.id), project);
}
