import { PROJECTS_COLLECTION } from './Project';
import { ProjectItemStatus } from './Status';

export interface Observation {
	id: string;
	name: string;
	description: string;
	location?: string;
	created: Date;
	updated: Date;
	status: ProjectItemStatus;
	userId: string;
	category: ObservationCategory;
	lastUpdatedByUserId?: string;
	lastUpdatedByUserName?: string;
}

export const OBSERVATION_COLLECTION = 'observations';
export const OBSERVATION_NOTES_COLLECTION = 'notes';

export enum ObservationCategory {
	SOLAR_AC = 'SOLAR_AC',
	SOLAR_DC = 'SOLAR_DC',
	DRAWINGS = 'DRAWINGS',
	RACKING = 'RACKING',
	ELECTRICAL = 'ELECTRICAL',
	ROOFING = 'ROOFING',
	ARCHITECTURAL = 'ARCHITECTURAL',
	CIVIL = 'CIVIL',
	CONVEYING_SYSTEM = 'CONVEYING_SYSTEM',
	INTERIOR_DESIGN = 'INTERIOR_DESIGN',
	KITCHEN_EQUIPMENT = 'KITCHEN_EQUIPMENT',
	LANDSCAPE = 'LANDSCAPE',
	MECHANICAL = 'MECHANICAL',
	OTHER = 'OTHER',
	PLUMBING = 'PLUMBING',
	STRUCTURAL = 'STRUCTURAL',
}

export const getProjectObservationsCollection = (projectId: string) =>
	`${PROJECTS_COLLECTION}/${projectId}/${OBSERVATION_COLLECTION}`;

// projects/:projectId/punch-items/:observationId/notes
export const getProjectObservationNotesCollection = (
	projectId: string,
	observationId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${OBSERVATION_COLLECTION}/${observationId}/${OBSERVATION_NOTES_COLLECTION}/`;
