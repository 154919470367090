import { PROJECTS_COLLECTION } from './Project';

export const RFI_COLLECTION = 'rfis';
export const RFI_NOTES_COLLECTION = 'notes';

export const getProjectRFIsCollection = (projectId: string) =>
	`${PROJECTS_COLLECTION}/${projectId}/${RFI_COLLECTION}`;

// projects/:projectId/rfis/:rfiId/notes
export const getProjectRFIsNotesCollection = (
	projectId: string,
	rfiId: string,
) =>
	`${PROJECTS_COLLECTION}/${projectId}/${RFI_COLLECTION}/${rfiId}/${RFI_NOTES_COLLECTION}/`;

export interface RequestForInformation {
	id: string;
	subject: string;
	created: Date;
	category: RequestForInformationCategory;
	severity: RequestForInformationSeverity;
	location?: string;
	question: string;
	status: RequestForInformationStatus;
	userId: string;
}

export enum RequestForInformationStatus {
	OPEN = 'OPEN',
	ANSWERED = 'ANSWERED',
	CLOSED = 'CLOSED',
	PENDING_APPROVED = 'PENDING_APPROVED',
}

export enum RequestForInformationSeverity {
	LOW = 'LOW',
	NORMAL = 'NORMAL',
	HIGH = 'HIGH',
	CRITICAL = 'CRITICAL',
}

export enum RequestForInformationCategory {
	SOLAR_AC = 'SOLAR_AC',
	SOLAR_DC = 'SOLAR_DC',
	DRAWINGS = 'DRAWINGS',
	RACKING = 'RACKING',
	ELECTRICAL = 'ELECTRICAL',
	ROOFING = 'ROOFING',
	ARCHITECTURAL = 'ARCHITECTURAL',
	CIVIL = 'CIVIL',
	CONVEYING_SYSTEM = 'CONVEYING_SYSTEM',
	INTERIOR_DESIGN = 'INTERIOR_DESIGN',
	KITCHEN_EQUIPMENT = 'KITCHEN_EQUIPMENT',
	LANDSCAPE = 'LANDSCAPE',
	MECHANICAL = 'MECHANICAL',
	OTHER = 'OTHER',
	PLUMBING = 'PLUMBING',
	STRUCTURAL = 'STRUCTURAL',
}
