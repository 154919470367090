import {
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonLabel,
	IonRow,
} from '@ionic/react';
import { OmegaUser } from '@omega/shared';
import { useRef } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { firebaseAuth } from '../..';
import { ionRegister } from '../../util/ionRegister';
import { RecursivePartial } from '../../util/recursivePartial';
import GooglePlacesAutocomplete from '../google-places-autocomplete/GooglePlacesAutocomplete';
import { PlaceResult } from '../google-places-autocomplete/types';
import PhoneNumberForm from '../phone-number-form/PhoneNumberForm';
import { ProfileFormProps } from './types';

const ProfileForm: React.FC<ProfileFormProps> = () => {
	const [user] = useAuthState(firebaseAuth);
	const attributions = useRef<HTMLDivElement>(null);
	// const [file, setFile] = useState<File | null>(null);
	const { t } = useTranslation();
	const {
		register,
		watch,
		getValues,
		setValue,
		formState: { errors },
	} = useFormContext<RecursivePartial<OmegaUser>>();

	// We should always have a user for this profile form.
	if (!user) {
		return null;
	}

	// Handle the update from the place result
	const placesChange = (change: PlaceResult) => {
		const addressComponents = change.address_components;
		const locality =
			addressComponents.find(comp => comp.types.includes('locality'))
				?.long_name ?? '';
		setValue('city', locality);
		const adminArea =
			addressComponents.find(comp =>
				comp.types.includes('administrative_area_level_1'),
			)?.long_name ?? '';

		setValue('state', adminArea);
		const postalCode =
			addressComponents.find(comp => comp.types.includes('postal_code'))
				?.long_name ?? '';
		setValue('zipCode', postalCode);
	};

	// const onSubmit = async (data: OmegaUser) => {
	// 	// upload the file if applicable.
	// 	if (file) {
	// 		if (!data.id) {
	// 			data.id = nanoid();
	// 		}
	// 		const storage = getStorage();
	// 		const storageRef = ref(
	// 			storage,
	// 			`${USER_FILE_BUCKET}/${user.uid}/${USER_ICON_FOLDER}`,
	// 		);
	// 		const { ref: uploadRef } = await uploadBytes(storageRef, file);
	// 		data.iconUploadBucket = uploadRef.bucket;
	// 		data.iconUploadPath = uploadRef.fullPath;
	// 	}
	// 	data.id = user.uid;
	// 	console.log(data);
	// 	onLoading(true);
	// 	try {
	// 		await saveOmegaUser(data);
	// 		onSave();
	// 	} catch (ex) {
	// 		console.error('There was an issue saving the user profile', ex);
	// 	} finally {
	// 		onLoading(false);
	// 	}
	// };

	const address = watch('address', getValues().address ?? '') ?? '';

	return (
		<>
			<IonGrid>
				<IonRow>
					<IonCol>
						<IonItem className={errors.firstName ? 'input-error' : ''}>
							<IonLabel className="input-required" position="stacked">
								{t('firstName')}
							</IonLabel>
							<IonInput spellCheck
								{...ionRegister(register('firstName', { required: true }))}
								placeholder={t('firstName')}
							></IonInput>
						</IonItem>
					</IonCol>
					<IonCol>
						<IonItem className={errors.lastName ? 'input-error' : ''}>
							<IonLabel className="input-required" position="stacked">
								{t('lastName')}
							</IonLabel>
							<IonInput spellCheck
								{...ionRegister(register('lastName', { required: true }))}
								placeholder={t('lastName')}
							></IonInput>
						</IonItem>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<IonItem className={errors.email ? 'input-error' : ''}>
							<IonLabel className="input-required" position="stacked">
								{t('email')}
							</IonLabel>
							<IonInput spellCheck
								{...ionRegister(register('email', { required: true }))}
								placeholder={t('email')}
							></IonInput>
						</IonItem>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						{attributions.current && (
							<GooglePlacesAutocomplete
								onInputChange={address => setValue('address', address)}
								inputValue={address}
								onPlaceSelect={placesChange}
								attributions={attributions.current}
							/>
						)}
					</IonCol>
				</IonRow>
			</IonGrid>
			<PhoneNumberForm require={false} />
			<div ref={attributions} id="attributions"></div>
		</>
	);
};

export default ProfileForm;
