import {
	IonButton,
	IonCol,
	IonGrid,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonRow,
	IonSelect,
	IonSelectOption,
} from '@ionic/react';
import { OmegaUser } from '@omega/shared';
import { addOutline, trashOutline } from 'ionicons/icons';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ionRegister } from '../../util/ionRegister';
import { RecursivePartial } from '../../util/recursivePartial';
import './style.css';
import { PhoneNumberProps } from './types';

const PHONE_NUMBERS_PROP = 'phoneNumbers';

const PhoneNumberForm: React.FC<PhoneNumberProps> = ({ require }) => {
	const {
		register,
		formState: { errors },
		setValue,
		watch,
	} = useFormContext<RecursivePartial<OmegaUser>>();
	const { t } = useTranslation();

	const currentPhoneNumbers = watch(PHONE_NUMBERS_PROP) ?? [];

	const deleteNumber = (toRemove: number) => {
		setValue(
			PHONE_NUMBERS_PROP,
			currentPhoneNumbers.filter((_value, index) => toRemove !== index),
		);
	};

	const addEmptyPhoneNumber = () => {
		setValue(PHONE_NUMBERS_PROP, [
			...currentPhoneNumbers,
			{
				label: 'cell',
				number: undefined,
			},
		]);
	};

	return (
		<IonGrid>
			{currentPhoneNumbers.map((_value, index) => {
				return (
					<IonRow key={index}>
						<IonCol size="4">
							<IonItem
								className={
									errors?.[PHONE_NUMBERS_PROP]?.[index]?.['label']
										? 'input-error'
										: ''
								}
							>
								<IonLabel
									className={require ? 'input-required' : ''}
									position="stacked"
								>
									{t('numberType')}
								</IonLabel>
								<IonSelect
									multiple={false}
									{...ionRegister(
										register(`${PHONE_NUMBERS_PROP}.${index}.label`, {
											required: require,
										}),
									)}
								>
									<IonSelectOption value="cell">{t('cell')}</IonSelectOption>
									<IonSelectOption value="home">{t('home')}</IonSelectOption>
									<IonSelectOption value="office">
										{t('office')}
									</IonSelectOption>
								</IonSelect>
							</IonItem>
						</IonCol>
						<IonCol size="6">
							<IonItem
								className={
									errors?.[PHONE_NUMBERS_PROP]?.[index]?.['number']
										? 'input-error'
										: ''
								}
							>
								<IonLabel
									className={require ? 'input-required' : ''}
									position="stacked"
								>
									{t('phoneNumber')}
								</IonLabel>
								<IonInput
									spellCheck
									{...ionRegister(
										register(`${PHONE_NUMBERS_PROP}.${index}.number`, {
											required: require,
										}),
									)}
									onIonChange={e => {
										const format = formatPhoneNumber(e.detail.value);
										setValue(
											`${PHONE_NUMBERS_PROP}.${index}.number`,
											format ?? '',
										);
									}}
									placeholder={t('phoneNumber')}
								></IonInput>
							</IonItem>
						</IonCol>
						<IonCol size="2" className="phone-delete-button-container">
							<IonButton
								disabled={require && currentPhoneNumbers.length === 1}
								onClick={() => deleteNumber(index)}
								fill="clear"
							>
								<IonIcon icon={trashOutline}></IonIcon>
							</IonButton>
						</IonCol>
					</IonRow>
				);
			})}
			<IonRow>
				<IonCol>
					<IonButton onClick={addEmptyPhoneNumber}>
						<IonIcon slot="start" icon={addOutline}></IonIcon>
						{t('addPhoneNumber')}
					</IonButton>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

function formatPhoneNumber(value?: string | null) {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value;

	// clean the input for any non-digit values.
	const phoneNumber = value.replace(/[^\d]/g, '');

	// phoneNumberLength is used to know when to apply our formatting for the phone number
	const phoneNumberLength = phoneNumber.length;

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early
	if (phoneNumberLength < 4) return phoneNumber;

	// if phoneNumberLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	// finally, if the phoneNumberLength is greater then seven, we add the last
	// bit of formatting and return it.
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
		3,
		6,
	)}-${phoneNumber.slice(6, 10)}`;
}

export default PhoneNumberForm;
