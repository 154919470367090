export const NOTIFICATIONS_COLLECTION = 'notifications';

export interface NotificationVariables {
	[key: string]: string;
}
export interface Notification {
	/**
	 * Id of the notification.
	 */
	id: string;
	/**
	 * The user's id who this notification is for.
	 */
	userId: string;
	/**
	 * The organization associated to this notification.
	 */
	organizationId: string;
	/**
	 * The title of the notification.
	 */
	title: string;
	/**
	 * The description of the notification.
	 */
	description: string;
	/**
	 * If a template should be used for an email.
	 */
	template?: string;
	/**
	 * Variables to be applied to the title/description
	 */
	variables?: NotificationVariables;
	/**
	 * Field shown if the user has viewed the notification.
	 */
	read: boolean;
	/**
	 * URL link to the resource generating the notification.
	 */
	link?: string;
	/**
	 * When the notification was created.
	 */
	timestamp: Date;
}
