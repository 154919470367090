import { useEffect, useState } from 'react';
import { makePdfThumb } from '../../util/image';
import PdfCanvas from '../pdf-viewer/PdfCanvas';

const PdfPreview: React.FC<{
	pdfUrl: string;
	generatedPdf: (pdfObjectUrl: string) => void;
}> = ({ pdfUrl, generatedPdf }) => {
	const [pageRender, setPageRender] = useState<HTMLCanvasElement>();

	const onPageRenderSuccess = (canvas: HTMLCanvasElement) => {
		setPageRender(canvas);
	};

	useEffect(() => {
		setPageRender(undefined);
	}, [pdfUrl]);

	useEffect(() => {
		async function getThumb() {
			// generate thumbnail from pdf
			if (!pageRender) {
				return;
			}
			const thumbnail = await makePdfThumb(pageRender, [450, 900]);
			if (thumbnail) {
				generatedPdf(thumbnail);
			}
		}
		getThumb();
	}, [generatedPdf, pageRender]);

	return (
		<div className="hidden-input">
			<PdfCanvas
				onPageRenderSuccess={onPageRenderSuccess}
				currentPage={1}
				pdfUrl={pdfUrl}
				style={{
					height: 0,
					width: 0,
					position: 'absolute',
					maxWidth: 0,
					maxHeight: 0,
					top: 0,
				}}
			/>
		</div>
	);
};
export default PdfPreview;
