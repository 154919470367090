import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';
import {
	accessibilityOutline,
	albumsOutline,
	alertCircleOutline,
	analyticsOutline,
	calendarOutline,
	cashOutline,
	checkmarkDoneOutline,
	clipboardOutline,
	constructOutline,
	documentOutline,
	documentTextOutline,
	fileTrayFullOutline,
	fileTrayStackedOutline,
	folderOpenOutline,
	folderOutline,
	gridOutline,
	helpCircleOutline,
	listOutline,
	logInOutline,
	mailOutline,
	medicalOutline,
	newspaperOutline,
	newspaperSharp,
	notificationsOutline,
	peopleOutline,
	personOutline,
	readerOutline,
	receiptOutline,
	snowOutline,
	telescopeOutline,
	fileTrayOutline,
} from 'ionicons/icons';

export const SEATS_ICON = ChairOutlinedIcon;
export const INVITE_ICON = mailOutline;
export const BUDGET_ICON = cashOutline;
export const CHECKLIST_TEMPLATE_ICON = checkmarkDoneOutline;
export const CHECKLISTS_ICON = listOutline;
export const DAILY_REPORT_ICON = snowOutline;
export const DRAWINGS_SPECS_ICON = clipboardOutline;
export const FIELD_MANAGEMENT_ICON = fileTrayFullOutline;
export const FIELD_WORK_DIRECTIVES_ICON = constructOutline;
export const LOGIN_ICON = logInOutline;
export const NOTICES_TO_COMPLY_ICON = alertCircleOutline;
export const PORTFOLIO_ICON = albumsOutline;
export const PORTFOLIO_SETTINGS_ICON = folderOutline;
export const PORTFOLIOS_ICON = folderOutline;
export const PROFILE_ICON = personOutline;
export const PROJECT_ICON = newspaperSharp;
export const PROJECT_SETTINGS_ICON = folderOutline;
export const PROJECTS_ICON = newspaperOutline;
export const PUNCH_ITEMS_ICON = readerOutline;
export const REQUEST_FOR_INFO_ICON = helpCircleOutline;
export const SAFETY_NOTICES_ICON = medicalOutline;
export const ASSIGNMENTS_ICON = fileTrayFullOutline;
export const ASSIGNMENT_ICON = documentTextOutline;
export const ORGANIZATION_ICON = peopleOutline;
export const TEAM_ICON = accessibilityOutline;
export const PROJECT_DOCUMENTS_ICON = folderOpenOutline;
export const NOTE_ICON = documentOutline;
export const ACTIVITY_LOG_ICON = fileTrayStackedOutline;
export const MILESTONES_ICON = analyticsOutline;
export const BOARD_ICON = gridOutline;
export const NOTIFICATIONS_ICON = notificationsOutline;
export const PROJECT_SCHEDULE_ICON = calendarOutline;
export const DAILY_REPORT_SUMMARY_ICON = receiptOutline;
export const FILLABLE_FORMS_ICON = fileTrayOutline;
export const OBSERVATION_ICON = telescopeOutline;
