import {
	FillableFormUpload,
	getFillableFormUploadCollectionString,
	getUserFillableFormUploadCollectionString,
	ProjectFillableForm,
} from '@omega/shared';
import {
	collection,
	CollectionReference,
	deleteDoc,
	doc,
	DocumentReference,
	setDoc,
} from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firehooks/firestore';
import { getGenericConverter } from '.';
import { firestoreApp } from '..';

const fillableFormConverter = getGenericConverter<FillableFormUpload>();
const projectFillableFormConverter = getGenericConverter<ProjectFillableForm>();

/**
 * Gets the omega fillable form collection for a project
 */
export function getFillableFormUploadCollection(
	organizationId: string,
): CollectionReference<FillableFormUpload> {
	return collection(
		firestoreApp,
		getFillableFormUploadCollectionString(organizationId),
	).withConverter(fillableFormConverter);
}

/**
 * Gets the omega project fillable form collection for a project
 */
export function getProjectFillableFormUploadCollection(
	projectId: string,
): CollectionReference<ProjectFillableForm> {
	return collection(
		firestoreApp,
		getUserFillableFormUploadCollectionString(projectId),
	).withConverter(projectFillableFormConverter);
}

export function getProjectFillableFormDoc(
	projectId: string,
	id?: string,
): DocumentReference<ProjectFillableForm> {
	return doc(getProjectFillableFormUploadCollection(projectId), id);
}

export function getFillableFormDoc(
	organizationId: string,
	id?: string,
): DocumentReference<FillableFormUpload> {
	return doc(getFillableFormUploadCollection(organizationId), id);
}

export function useFillableForm(organizationId: string, id?: string) {
	return useDocument(getFillableFormDoc(organizationId, id));
}

export function useProjectForm(projectId: string, id?: string) {
	return useDocument(getProjectFillableFormDoc(projectId, id ?? 'n/a'));
}

export function saveFillableForm(
	organizationId: string,
	fillableForm: FillableFormUpload,
) {
	return setDoc<FillableFormUpload>(
		doc(getFillableFormUploadCollection(organizationId), fillableForm.id),
		fillableForm,
	);
}

export function saveProjectFillableForm(
	projectId: string,
	fillableForm: ProjectFillableForm,
) {
	return setDoc<ProjectFillableForm>(
		doc(getProjectFillableFormUploadCollection(projectId), fillableForm.id),
		fillableForm,
	);
}

export function getProjectFormDoc(
	projectId: string,
	id?: string,
): DocumentReference<ProjectFillableForm> {
	return doc(getProjectFillableFormUploadCollection(projectId), id);
}

export function useProjectForms(projectId: string) {
	return useCollection<ProjectFillableForm>(
		getProjectFillableFormUploadCollection(projectId),
	);
}

export function useOrganizationForms(orgId: string) {
	return useCollection<FillableFormUpload>(
		getFillableFormUploadCollection(orgId),
	);
}

export function deleteOrgForm(orgId: string, formId: string) {
	return deleteDoc(doc(getFillableFormUploadCollection(orgId), formId));
}

export function deleteProjectForm(projectId: string, formId: string) {
	return deleteDoc(
		doc(getProjectFillableFormUploadCollection(projectId), formId),
	);
}
