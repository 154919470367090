import { QuerySnapshot } from 'firebase/firestore';
import Fuse from 'fuse.js';
import { useCallback, useMemo, useState } from 'react';

export interface UseFuseOptions<T, U> extends Fuse.IFuseOptions<T> {
	transform?: (doc: T) => U;
}

export function mapToFuseResult<T>(list: T[]): Fuse.FuseResult<T>[] {
	return list.map((item, index) => {
		const fuseResult: Fuse.FuseResult<T> = {
			item,
			refIndex: index,
		};
		return fuseResult;
	});
}

export function useFuse<T, U extends T>(
	list: T[] | undefined | QuerySnapshot<T>,
	options: UseFuseOptions<T, U>,
) {
	// defining our query state in there directly
	const [query, updateQuery] = useState<string | undefined>('');
	const { transform, ...fuseOptions } = options;
	let itemArray: T[];

	if (list instanceof QuerySnapshot) {
		itemArray = list.docs.map(doc => doc.data());
	} else if (list) {
		itemArray = list;
	} else {
		itemArray = [];
	}

	let items: T[] | U[] = itemArray;
	if (transform) {
		items = itemArray.map(transform);
	}

	// let's memoize the fuse instance for performances
	const fuse = useMemo(() => {
		return new Fuse(items, {
			ignoreLocation: true,
			...fuseOptions,
		});
	}, [fuseOptions, items]);

	// memoize results whenever the query or options change
	const hits: Fuse.FuseResult<T>[] | Fuse.FuseResult<U>[] = useMemo(
		() => (!query ? mapToFuseResult(items) : fuse.search(query)),
		[fuse, items, query],
	);

	// pass a handling helper to speed up implementation
	const onSearch = useCallback(
		e => updateQuery(e.target.value.trim()),
		[updateQuery],
	);

	// still returning `setQuery` for custom handler implementations
	return {
		hits,
		onSearch,
		query,
		setQuery: updateQuery,
	};
}
