import { getStorage, ref } from 'firebase/storage';
import { useDownloadURL } from 'react-firehooks/storage';

export function getFileRef(path: string) {
	const storage = getStorage();
	return ref(storage, path);
}

export function useFileDownloadUrl(path: string | undefined) {
	return useDownloadURL(path === undefined ? undefined : getFileRef(path));
}
