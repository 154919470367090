import {
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuToggle,
} from '@ionic/react';
import { OrganizationRole } from '@omega/shared';
import { useAuthState } from 'react-firehooks/auth';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { firebaseAuth } from '../..';
import { useUserHasOrgPrivilege } from '../../database/permissions';
import { usePreferenceState } from '../../state/preference-state';
import {
	adminAppPages,
	commonAppPages,
	loggedInAppPages,
	loggedOutAppPages,
	userAppPages,
} from './contants';
import './Menu.css';
import './style.css';
import { AppPage } from './types';

const Menu: React.FC = () => {
	const location = useLocation();
	const [user] = useAuthState(firebaseAuth);
	const { t } = useTranslation();

	const preference = usePreferenceState();
	const pages = [
		...commonAppPages,
		...(user ? loggedInAppPages : loggedOutAppPages),
	];

	const [isAdmin] = useUserHasOrgPrivilege([
		OrganizationRole.OWNER,
		OrganizationRole.ADMIN,
	]);

	if (isAdmin) {
		pages.push(...adminAppPages);
	}

	const userPages: AppPage[] = user ? userAppPages : [];

	const mapPage = (appPage: AppPage) => {
		return (
			<IonMenuToggle key={appPage.url} autoHide={false}>
				<IonItem
					className={location.pathname === appPage.url ? 'selected' : ''}
					routerLink={appPage.url}
					routerDirection="none"
					lines="none"
					detail={false}
				>
					<IonIcon color="primary" slot="start" icon={appPage.iosIcon} />
					<IonLabel>{t(appPage.titleToTranslate)}</IonLabel>
				</IonItem>
			</IonMenuToggle>
		);
	};

	return (
		<IonMenu contentId="main" type="overlay">
			<IonContent className="menu-content">
				<div className="menu-header">
					<img
						className=""
						src={
							preference.value?.darkMode
								? '/assets/convertSolar.png'
								: '/assets/convertSolarLogoDark.png'
						}
						alt="logo"
					/>
				</div>
				{!!pages.length && (
					<IonList id="inbox-list">{pages.map(mapPage)}</IonList>
				)}

				<IonList id="labels-list">{userPages.map(mapPage)}</IonList>
			</IonContent>
		</IonMenu>
	);
};

export default Menu;
