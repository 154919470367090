/**
 * 
 * Subjects:
Approved Assignment
{{assignmentName}} has been approved for {{projectName}}.

Completed Assignment
{{assignmentName}} has been completed for {{projectName}}.

New Assignment
New assignment {{assignmentName}} for {{projectName}}.


Pending Approved Assignment
{{assignmentName}} has been completed and awaiting approval for {{projectName}}.


Reopen Assignment
{{assignmentName}} has been reopened for {{projectName}}.
 */


export const EMAIL_COLLECTION = 'mail';

export interface Email {
	/**Send to a specific email */
	to?: string | string[];
	/** cc field of the email */
	cc?: string | string[];
	/** bcc field of the email */
	bcc?: string | string[];
	/**
	 * User ids to send the email to.
	 */
	toUids?: string[];
	/**
	 * User ids to cc the email to.
	 */
	ccUids?: string[];
	/**
	 * User ids to bcc the email to.
	 */
	bbcUids?: string[];
	/** The template name to send */
	template?: SendEmailTemplate<
		| AssignmentEmailVariables
		| NewDrawingEmailVariables
		| ChecklistCompletedEmailVariables
		| UserEmailVariables
	>;
	/**
	 * The message to send.
	 */
	message?: EmailMessage;
	/**Headers to be set on the email. */
	headers?: StringKeyValuePair;
}

export interface UserEmailVariables extends BaseEmailVariables {
	inviterName: string;
}

export interface EmailMessage {
	/**
	 * Email subject line
	 */
	subject: string;
	/**
	 * 'This is the <code>HTML</code> section of the email body.',
	 */
	html?: string;
	/** 'This is the plaintext section of the email body.' */
	text?: string;
	/**
	 * A message ID header for the email, if any.
	 */
	messageId?: string;
}

export interface SendEmailTemplate<T extends BaseEmailVariables> {
	name: EmailTemplates;
	data: T;
}

export interface StringKeyValuePair {
	[key: string]: string;
}

/**
 * 
 * {
    subject: "@{{username}} is now following you!",
    html: "Just writing to let you know that <code>@{{username}}</code> ({{name}}) is now following you.",
  }
 * 
 * 
 * {
     partial: true,
     html: "<p>This mail was sent by ExampleApp, Inc. <a href='https://example.com/unsubscribe'>Unsubscribe</a></p>",
     text: "This mail was sent by ExampleApp, Inc. Unsubscribe here: https://example.com/unsubscribe"
  }

  EXAMPLE:

  <p>This is my main template content, but it will use a common footer.</p>

  {{> footer }}

 * 
 */
export interface EmailTemplate {
	partial?: boolean;
	/**
	 * A template string for the subject of the email.
	 */
	subject?: string;
	/**
	 *  A template string for the plaintext content of the email.
	 */
	text?: string;
	/**
	 * A template string for the HTML content of the email.
	 */
	html?: string;
}

export interface AssignmentEmailVariables extends BaseEmailVariables {
	/***
	 * The task type string.
	 */
	taskType: string;
	/**
	 * When the assignment is due.
	 */
	dueDate?: string;
	/**
	 * Name of the assignment.
	 */
	assignmentName: string;
}

export interface CompletedAssignmentEmailVariables
	extends AssignmentEmailVariables {
	/***
	 * The user who completed the assignment.
	 */
	completedUser: string;
}

export interface NewDrawingEmailVariables extends BaseEmailVariables {
	/**
	 * The drawing name that got uploaded.
	 */
	drawingName: string;
}

export interface BaseEmailVariables {
	/**
	 * Organization Name
	 */
	organizationName: string;
	/**
	 * The project name for the assignment.
	 */
	projectName: string;
	/**
	 * Http link to the item.
	 */
	link: string;
	/**
	 * The link text.
	 */
	linkText: string;
}

export type EmailTemplates =
	| 'ChecklistCompleted'
	| 'NewDrawing'
	| 'NewAssignment'
	| 'CompletedAssignment'
	| 'UserInvite'
	| 'ApprovedAssignment'
	| 'PendingApprovedAssignment'
	| 'ReopenAssignment'
	| 'UserCreated';

export interface ChecklistCompletedEmailVariables extends BaseEmailVariables {
	/**
	 * The name of the checklist that was completed.
	 */
	checklistName: string;
	/**
	 * The user who completed. the checklist.
	 */
	completedUser: string;

	linkText: 'View Checklist';
}
