import {
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Catch from './Catch';

type Props = {
	children: React.ReactNode;
};

const Contents: React.FC = () => {
	const { t } = useTranslation();
	return (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle>{t('error')}</IonCardTitle>
				<IonCardSubtitle>{t('anErrorOccurred')}</IonCardSubtitle>
			</IonCardHeader>
		</IonCard>
	);
};

const ErrorBoundary = Catch(function ErrorBoundary(
	props: Props,
	error?: Error,
) {
	if (error) {
		return <Contents />;
	} else {
		return <React.Fragment>{props.children}</React.Fragment>;
	}
});

export default ErrorBoundary;
